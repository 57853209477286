import { Box, Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { BiSolidLocationPlus } from "react-icons/bi";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import Commonservices from "../../service/Commonservices";
import { useDispatch } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";

const Notificationcard = ({
  isRead,
  notificationData,
  handleFetchNotification,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = () => {
    navigate("/track");
  };

  const handleMarkAsReached = async (id) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = { id: id };
      let apiResult = await mysqlservice.post("mark_as_read", req);
      if (apiResult.status == 200) {
        toast.success("Notification marked as read");
        handleFetchNotification();
      } else {
        toast.error("Something went wrong");
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {notificationData && notificationData.length ? (
        notificationData.map((data, index) => {
          return (
            <Card
              key={index}
              sx={{
                p: 2,
                boxShadow: 0,
                border: "1px solid #dee2e6",
              }}
              // onClick={handleNavigate}
            >
              <Grid container>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <Box
                      p={1}
                      sx={{
                        bgcolor: "#caf0f8",
                        width: "fit-content",
                        borderRadius: "4px",
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      {data.type == "clinic" ? (
                        <BiSolidLocationPlus
                          style={{
                            height: "22px",
                            width: "22px",
                            color: "#3BA5B9",
                          }}
                        />
                      ) : (
                        <PeopleIcon
                          style={{
                            height: "22px",
                            width: "22px",
                            color: "#3BA5B9",
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography>{data.title}</Typography>
                      <Typography sx={{ color: "#6c757d" }}>
                        {data.message}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <Typography sx={{ color: "#6c757d" }}>
                    {moment(data.createdAt).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1.5}>
                  {isRead ? (
                    ""
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#3BA5B9",
                        color: "#fff",
                        textTransform: "uppercase",
                      }}
                      onClick={() => {
                        handleMarkAsReached(data.id);
                      }}
                    >
                      Mark as read
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Card>
          );
        })
      ) : (
        <Card
          sx={{
            p: 2,
            boxShadow: 0,
            border: "1px solid #dee2e6",
            textAlign: "center",
          }}
        >
          <Typography>No Notification to Show</Typography>
        </Card>
      )}
    </Box>
  );
};

export default Notificationcard;
