import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Breadcrums from "../../../Components/Breadcrums";
import SimpleDropdown from "../../../Components/SimpleDropdown";
import Datefeild from "../../../Components/Datefeild";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Commonservices from "../../../service/Commonservices";
import ApiService from "../../../service/apiService";
import APPCONSTANTS from "../../../config/constant";
import { useDispatch, useSelector } from "react-redux";
import { setBatch, setClass } from "../../../state";
import { useTheme } from "@emotion/react";
import moment from "moment";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Emailresend2 from "../../../Asset/resend.png";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import CheckupDrawer from "./CheckupDrawer";
import mysqlservice from "../../../service/mySqlservice";
import { toast } from "react-toastify";

const HealthIndex = () => {
  const [allClass, setAllClass] = useState([]);
  const [allBatch, setAllBatch] = useState([]);
  const [classNbatchData, setClassNBatchData] = useState([]);
  const [selectedClass, setselectedClass] = useState("");
  const [selectedBatch, setselectedBatch] = useState("");
  const [filterdStudentList, setfilterdStudentList] = useState([]);
  const [checked, setchecked] = useState([]);
  const [allchecked, setAllchecked] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});

  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const classData = useSelector((state) => state.class);
  const batchData = useSelector((state) => state.batch);
  const classInRoman = APPCONSTANTS.classInRoman;
  const containerRef = useRef();

  useEffect(() => {
    handleclassNbatch();
  }, []);

  const checkupTypes = [
    { id: 1, name: "None", value: "" },
    { id: 2, name: "General Built", value: "General Built" },
    { id: 3, name: "Eyes", value: "Eyes" },
    { id: 4, name: "Ears", value: "Ears" },
    { id: 5, name: "Oral Health", value: "Oral Health" },
    { id: 6, name: "Scoliosis", value: "Scoliosis" },
    { id: 7, name: "Mental Health", value: "Mental Health" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      checkup_type: "",
      date: "",
      class: "",
      batch: "",
    },
    onSubmit: (value) => {
      console.log(value);
    },
  });

  const handleDateChange = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      formik.setFieldValue("date", newDate);
    } else {
      formik.setFieldValue("date", "");
    }
  };

  const handleclassNbatch = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
      };

      let apiResult = await ApiService.post(
        "bm-school/Docme-UI/registration/get_student_class",
        req
      );

      const combinedClasses = {};

      apiResult.forEach((item) => {
        const foundClass = APPCONSTANTS.classInRoman.find(
          (c) => c.roman === item.class
        );

        if (foundClass) {
          const classNumber = foundClass.number;

          if (combinedClasses[classNumber]) {
            combinedClasses[classNumber].batch = [
              ...new Set([
                ...combinedClasses[classNumber].batch,
                ...item.batch,
              ]),
            ];
          } else {
            combinedClasses[classNumber] = {
              class: classNumber,
              batch: item.batch,
            };
          }
          combinedClasses[classNumber].batch =
            combinedClasses[classNumber].batch.sort();
        }
      });

      const updatedClassNbatch = Object.values(combinedClasses);

      const sortedClassNbatch = updatedClassNbatch.sort((a, b) => {
        if (a.class === "KG1") return -1;
        if (b.class === "KG1") return 1;
        if (a.class === "KG2") return -1;
        if (b.class === "KG2") return 1;
        return a.class - b.class;
      });

      const classes = sortedClassNbatch.map((data) => {
        return {
          value: data.class,
          class: data.class,
        };
      });
      setClassNBatchData(sortedClassNbatch);
      setAllClass(classes);

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleClassSelect = (value) => {
    formik.setFieldValue("class", value);
    dispatch(
      setClass({
        class: value,
      })
    );
    setselectedClass(value);
    const Allbatches =
      classNbatchData &&
      classNbatchData.find((data) => data.class === value)?.batch;

    const batches = Allbatches.map((data) => {
      return {
        value: data,
        batch: data,
      };
    });

    setAllBatch(batches);
  };

  const handleBatchSelect = async (value) => {
    setselectedBatch(value);
    formik.setFieldValue("batch", value);
    dispatch(
      setBatch({
        batch: value,
      })
    );
    // await handleStudentData(value);
  };

  const handleStudentData = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        class: selectedClass ? selectedClass : classData,
        batch: selectedBatch ? selectedBatch : batchData,
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
      };
      const apiResult = await ApiService.post(
        "bm-school/Docme-UI/registration/get_student_data",
        req
      );

      for (let val of apiResult) {
        let parts = val && val.batch.split("/");

        let romanClass = parts[0];
        let partBatch = parts[1];

        let partClass = await classInRoman.find(
          (val) => val.roman == romanClass
        );
        val.class = partClass.number;
        val.batch_detail = partBatch;
      }

      await getCheckupData(selectedBatch, apiResult);
      // setselectedBatch("");

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const getCheckupData = async (batch, studentData) => {
    try {
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        checkup_type: formik.values.checkup_type,
        class: formik.values.class,
        batch: formik.values.batch,
      };

      let apiresult = await mysqlservice.post("get_checkup", req);

      let res_val = apiresult ? apiresult.data : [];

      let combinedData = studentData.map((data) => {
        const addData = res_val.find((val) => val.admission_no == data.Admn_No);
        return {
          ...data,
          ...(addData
            ? {
                checkup_id: addData.id,
                checkup_type: addData.checkup_type,
                checkup_date: addData.checkup_date,
                checkup_status: addData.checkup_status,
                remarks: addData.remarks,
              }
            : {}),
        };
      });

      setfilterdStudentList(combinedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxSelect = (id, isChecked) => {
    let checkedlist = [...checked];

    if (isChecked) {
      checkedlist = [...checked, id];
    } else {
      setAllchecked(false);
      checkedlist.splice(checked.indexOf(id), 1);
    }

    setchecked(checkedlist);

    // Check if all students are selected
    if (checkedlist.length === filterdStudentList.length) {
      setAllchecked(true);
    }
  };

  const handleAllChecked = (ischecked) => {
    let checkedList = [];
    if (ischecked) {
      setAllchecked(true);
      for (let index = 0; index < filterdStudentList.length; index++) {
        let element = filterdStudentList[index];
        checkedList.push(element);
      }
    } else {
      setAllchecked(false);
    }
    setchecked(checkedList);
  };

  const handleScrolll = (scrollAmt) => {
    containerRef.current.scrollLeft += scrollAmt;
  };

  const handleDrawerOpen = (val) => {
    if (val.checkup_id) {
      setDrawerOpen(true);
      setSelectedStudent(val);
    } else {
      toast.error("Health checkup notification not send yet");
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSendAll = async () => {
    // const selectedDate = formik.values.date
    // let checkup_date = moment.utc(selectedDate).format("YYYY-MM-DD");
    console.log(formik.values.date);

    if (formik.values.checkup_type === "") {
      toast.error("Please Select Checkup Type");
    } else if (formik.values.date === "") {
      toast.error("Please Select Checkup Date");
    } else if (checked.length === 0) {
      toast.error("Have to select atleast 1 student to continue");
    } else {
      try {
        Commonservices.loaderON(dispatch);
        let req = {
          studentData: checked,
          checkup_type: formik.values.checkup_type,
          checkup_date: formik.values.date,
          class: formik.values.class,
          batch: formik.values.batch,
          academic_year: userData.academic_year,
          institution_id: userData.institution_id,
        };

        let apiResult = await mysqlservice.post("sent_checkup_all", req);
        toast.success("Health Checkup Notification Sent Successfully");
        setchecked([]);
        handleStudentData();
        Commonservices.loaderOFF(dispatch);
      } catch (error) {
        console.log(error);
        Commonservices.loaderOFF(dispatch);
      }
    }
  };

  return (
    <Box>
      <Breadcrums
        title={[
          {
            title: "HEALTH CHECK UP ALERT",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box sx={{ p: 2 }}>
        <Card sx={{ p: 2 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{ xs: 1, xl: 2 }}
            p={2}
          >
            <SimpleDropdown
              label={"Select Health Checkup type"}
              style={{ width: "210px" }}
              value={formik.values.checkup_type}
              onChange={(value) => {
                formik.setFieldValue("checkup_type", value.target.value);
              }}
              menuitem={checkupTypes}
            />

            <Datefeild
              // label={"Date"}
              placeholder={"Select Date"}
              value={formik.values.date}
              onChange={handleDateChange}
              style={{ width: "150px" }}
            />

            <FormControl size="small" sx={{ width: "148px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Class
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedClass}
                label="Select Class"
                onChange={(e) => {
                  handleClassSelect(e.target.value);
                }}
              >
                {allClass && allClass.length
                  ? allClass.map((data, index) => {
                      return (
                        <MenuItem value={data.value} key={index}>
                          {data.class}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: "148px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBatch}
                label="Select Batch"
                onChange={(e) => {
                  handleBatchSelect(e.target.value);
                }}
              >
                {allBatch && allBatch.length
                  ? allBatch.map((data, index) => {
                      return (
                        <MenuItem value={data.value} key={index}>
                          {data.batch}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
              onClick={handleStudentData}
            >
              Apply
            </Button>

            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "capitalize",
                ml: "auto",
              }}
              onClick={handleSendAll}
            >
              Send All
            </Button>
          </Box>

          <TableContainer
            sx={{
              //   boxShadow: "0px 0px 2px black",
              display: "flex",
              flexDirection: "column",
            }}
            ref={containerRef}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell>
                    <Checkbox
                      size="small"
                      checked={allchecked}
                      onChange={(e) => {
                        handleAllChecked(e.target.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Sl
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Class & Batch
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Email Id
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Mobile No
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Gender
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Check Up Type
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Check Up Date
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Check Up Status
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { lg: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Remarks
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={
                      theme.palette.mode == "dark"
                        ? {
                            fontSize: { lg: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#343a40",
                          }
                        : {
                            fontSize: { lg: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#F3F6F9",
                          }
                    }
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterdStudentList && filterdStudentList.length ? (
                  filterdStudentList.map((val, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            size="small"
                            id={val.id}
                            name={val.id}
                            value={val.id}
                            checked={checked.includes(val) ? true : false}
                            onChange={(e) => {
                              handleCheckboxSelect(val, e.target.checked);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                            color: "#915ECC",
                          }}
                        >
                          {index < 9 ? 0 : null}
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Avatar
                              src={val.profile_image}
                              sx={{ height: "32px", width: "32px" }}
                            />
                            {val.student_name}
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          Grade {val.class} - {val.batch_detail}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_email}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_phone}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.gender ? val.gender : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.checkup_type ? val.checkup_type : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.checkup_date
                            ? moment.utc(val.checkup_date).format("DD MMM YYYY")
                            : "--"}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: { lg: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.checkup_status ? val.checkup_status : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { lg: "12px", xl: "14px" },
                            // whiteSpace: "nowrap",
                          }}
                        >
                          {val.remarks ? (
                            <Box
                              sx={{
                                height: val.remarks.length > 50 ? "80px" : "auto",
                                width:
                                  val.remarks.length > 50 ? "200px" : "auto",
                                overflow: "auto",
                              }}
                            >
                              {val.remarks ? val.remarks : "--"}
                            </Box>
                          ) : (
                            "--"
                          )}
                        </TableCell>
                        <TableCell
                          sx={
                            theme.palette.mode == "dark"
                              ? {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#343a40",
                                }
                              : {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#F3F6F9",
                                }
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              // width: "80px",
                            }}
                          >
                            <Tooltip title="Scroll left" placement="top">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: -8,
                                  top: 15,
                                  backgroundColor: "#fff",
                                  border: "1px solid gray",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() => handleScrolll(400)}
                              >
                                <KeyboardArrowRightIcon
                                  style={{ fontSize: "20px", color: "gray" }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Scroll Right">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: -8,
                                  top: 35,
                                  backgroundColor: "#fff",
                                  border: "1px solid gray",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() => handleScrolll(-400)}
                              >
                                <KeyboardArrowLeftIcon
                                  style={{ fontSize: "20px", color: "gray" }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Send Checkup Status">
                              <IconButton
                                sx={{
                                  borderRadius: "5px",
                                  backgroundColor: "#D0F2F9",
                                  width: "fit-content",
                                  transform: "translate(5px,0px)",
                                }}
                                onClick={() => {
                                  handleDrawerOpen(val);
                                }}
                              >
                                <ForwardToInboxIcon
                                  style={{ color: "#3BA5B9" }}
                                />
                              </IconButton>
                            </Tooltip>

                            {/* <Tooltip title="View Consent Form">
                              <IconButton
                                sx={{
                                  borderRadius: "5px",
                                  backgroundColor: "#D0F2F9",
                                  width: "fit-content",
                                }}
                                // onClick={() =>
                                //   handleredirectForm(val.consent_id, val)
                                // }
                              >
                                <MdOutlineRemoveRedEye
                                  style={{ color: "#3BA5B9" }}
                                />
                              </IconButton>
                            </Tooltip> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={14}>
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{
              m: 1,
              display: "flex",
              justifyContent: "end",
              "& .Mui-selected": {
                color: "#fff",
                backgroundColor: "#3BA5B9",
              },
              "& .MuiPaginationItem-page": {
                color: "#3BA5B9",
                "&.Mui-selected": {
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                },
              },
            }}
            // count={pageCount}
            // page={pages}
            // onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Card>
      </Box>

      <CheckupDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        studentData={selectedStudent}
        handleStudentData={handleStudentData}
      />
    </Box>
  );
};

export default HealthIndex;
