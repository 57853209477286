import React, { useState } from "react";
import { Calendar, Select, Row, Col } from "antd";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setSideBar } from "../../state";
import { useDispatch, useSelector } from "react-redux";

const Calendarcomp = ({ setselectMonth, visitData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dark = theme.palette.neutral.light;
  const userData = useSelector((state) => state.user);

  const getListData = (value) => {
    const dateStr = value.format("YYYY-MM-DD");
    let eventData =
      visitData && visitData.visits
        ? visitData.visits.filter((event) => event.visit_date == dateStr)
        : "";
    return eventData;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData
          ? listData.map((item, index) => (
              <li key={index}>
                <span>{item.count}</span>
              </li>
            ))
          : ""}
      </ul>
    );
  };

  const onSelect = (value, mode) => {
    if (userData.role != 20) {
      if (value && mode.source === "date") {
        const dateStr = value.format("YYYY-MM-DD");
        navigate(`/details/${dateStr}/${true}`);
        dispatch(setSideBar({ listItem: 2 }));
      }
    }
  };

  const onPanelChange = (value) => {
    const dateStr = value.format("YYYY-MM-DD");
    setselectMonth(dateStr);
  };

  const headerRender = ({ value, onChange }) => {
    const monthOptions = [];
    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
  
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.months(current.month(i)));
    }
  
    for (let i = 0; i < 12; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i} className="month-item">
          {months[i]}
        </Select.Option>
      );
    }
  
    const year = value.year();
    const month = value.month();
    const years = [];
    for (let i = year - 10; i <= year + 10; i++) {
      years.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }
  
    const handleMonthChange = (newMonth) => {
      const newValue = value.clone().month(newMonth);
      onChange(newValue);
    };
  
    const handleYearChange = (newYear) => {
      const newValue = value.clone().year(newYear);
      onChange(newValue);
    };
  
    return (
      <Box
        sx={{
          padding: { xs: 1, md: 2 },
          display: { xs: "block", md: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
          pt: 0,
          borderRadius: 0,
          boxShadow: 0,
        }}
        component={Paper}
      >
        <Typography
          sx={
            theme.palette.mode === "dark"
              ? { color: "#fff" }
              : { color: "#000" }
          }
        >
          CLINIC VISITS
        </Typography>
        <Box sx={{ pt: { xs: 2, md: 0 } }}>
          <Row gutter={8}>
            <Col>
              <Select
                style={{ width: "100px" }}
                value={month}
                onChange={handleMonthChange}
              >
                {monthOptions}
              </Select>
            </Col>
            <Col>
              <Select
                style={{ width: "80px" }}
                value={year}
                onChange={handleYearChange}
              >
                {years}
              </Select>
            </Col>
          </Row>
        </Box>
      </Box>
    );
  };
  

  return (
    <Calendar
      onSelect={onSelect}
      headerRender={headerRender}
      cellRender={dateCellRender}
      onPanelChange={onPanelChange}
      className={theme.palette.mode === "dark" ? "dark-calendar" : ""}
    />
  );
};

export default Calendarcomp;
