import { Box, Button, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Breadcrums from "../../Components/Breadcrums";
import Notificationcard from "./Notificationcard";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AllnotificationIndex = ({
  notificationData,
  handleFetchNotification,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const unReadMessages =
    notificationData && notificationData.length
      ? notificationData.filter((data) => data.status == false)
      : [];

  const readMessages =
    notificationData && notificationData.length
      ? notificationData.filter((data) => data.status == true)
      : [];

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "NOTIFICATIONS",
            icon: "",
            link: true,
            path: "/home",
          },
        ]}
      />
      <Box p={2}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            {/* <Button
              variant="contained"
              size="small"
              sx={{
                position: "absolute",
                right: 50,
                top:140,
                backgroundColor: "#3BA5B9",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              Mark all Read
            </Button> */}
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { backgroundColor: "#3BA5B9" } }}
            >
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "#3BA5B9",
                    fontWeight: 600,
                  },
                }}
                label="Unread"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "#3BA5B9",
                    fontWeight: 600,
                  },
                }}
                label="Read"
                {...a11yProps(1)}
              />
              {/* {value === 0 ? (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    textTransform: "uppercase",
                    ml: "auto",
                    mb: 1,
                  }}
                >
                  Mark all Read
                </Button>
              ) : (
                ""
              )} */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Notificationcard
              isRead={false}
              notificationData={unReadMessages}
              handleFetchNotification={handleFetchNotification}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Notificationcard isRead={true} notificationData={readMessages} />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default AllnotificationIndex;
