import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PiVirus, PiSpiralBold, PiBandaidsLight } from "react-icons/pi";
import Commonservices from "../../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../../service/mySqlservice";
import { toast } from "react-toastify";
import Textfield from "../../../Components/Textfield";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SendAllDrawer = ({
  open,
  onClose,
  studentData,
  vaccinationData,
  handleStudentData,
  setAllchecked,
  setchecked,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state) => state.user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: "",
      content: "",
    },
    onSubmit: (val) => {
      console.log(val);
    },
  });

  const handleDrawerClose = () => {
    onClose();
    formik.resetForm();
  };

  const handleSendAll = async () => {
    try {
      if (vaccinationData.vaccination_type == "") {
        toast.error("Vaccination type  required");
      } else if (vaccinationData.alert_type == "") {
        toast.error("Alert type required");
      } else if (vaccinationData.dose == "") {
        toast.error("Dose required");
      } else if (vaccinationData.date == "") {
        toast.error("Date required");
      } else if (formik.values.subject == "") {
        toast.error("Subject required");
      } else if (formik.values.content == "") {
        toast.error("Content required");
      } else {
        Commonservices.loaderON(dispatch);
        let req = {
          institution_id: userData.institution_id,
          academic_year: userData.academic_year,
          subject: formik.values.subject,
          content: formik.values.content,
          studentData: studentData,
          vaccinationData: vaccinationData,
        };
        let apiResult = mysqlservice.post("sentall_notification", req);
        toast.success(`${vaccinationData.alert_type} send successfully`);
        handleStudentData();
        handleDrawerClose();
        setAllchecked(false);
        setchecked([]);
        Commonservices.loaderOFF(dispatch);
      }
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={handleDrawerClose}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Sent Notification
            </Typography>
            <Typography fontSize="12px">
              Enter Subject and Content for Notification
            </Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handleDrawerClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Box display={"flex"} flexDirection={"column"} mt={2}>
            <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
              Subject
            </Typography>
            <textarea
              cols="30"
              rows="2"
              style={{
                outline: 0,
                borderColor: "#DFDFDF",
                padding: 15,
                color: "#000",
              }}
              value={formik.values.subject}
              onChange={(e) => {
                formik.setFieldValue("subject", e.target.value);
              }}
            ></textarea>
          </Box>

          <Box display={"flex"} flexDirection={"column"} mt={2}>
            <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
              Content
            </Typography>
            <textarea
              cols="30"
              rows="10"
              style={{
                outline: 0,
                borderColor: "#DFDFDF",
                padding: 15,
                color: "#000",
              }}
              value={formik.values.content}
              onChange={(e) => {
                formik.setFieldValue("content", e.target.value);
              }}
            ></textarea>
          </Box>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handleDrawerClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
              onClick={handleSendAll}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SendAllDrawer;
