import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import GeneralFirstpage from "./GeneralconsentForm/GeneralFirstpage";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepIcon from "@mui/material/StepIcon";
import StudentMedicalHistory from "./GeneralconsentForm/StudentMedicalHistory";
import FamilyMedicalHistory from "./GeneralconsentForm/FamilyMedicalHistory";

const CustomStepIcon = styled(StepIcon)(({ theme, active, completed }) => ({
  color: active || completed ? "green" : "white",
  border: "2px solid #A1AEBE",
  borderRadius: "50%",
  height: "32px",
  width: "32px",
  "& .MuiStepIcon-text": {
    fill: active || completed ? "#3BA5B9" : "#000",
    fontSize: "14px",
  },
  "&.Mui-active": {
    color: "#fff",
    border: "2px solid #3BA5B9",
    borderRadius: "50%",
  },
  "&.Mui-completed": {
    color: "#fff",
    border: "2px solid #A1AEBE",
    borderRadius: "50%",
    fill: "#3BA5B9",
  },
}));

const steps = ["Step 1", "Step 2", "Step 3"];

export default function StepperComp({
  studentData,
  studentMedicalHistory,
  familyMedicalHistory,
  activeStep,
  setActiveStep,
}) {
  const ismobilescreen = useMediaQuery("(max-width: 900px)");

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleResetForm = () => {
    studentData.resetForm();
    studentMedicalHistory.resetForm();
    familyMedicalHistory.resetForm();
  };

  return (
    <Box sx={{ width: "100%", p: 4 }}>
      <Stepper
        activeStep={activeStep}
        sx={
          ismobilescreen
            ? { width: "100%", margin: "auto" }
            : { width: "40%", margin: "auto" }
        }
        alternativeLabel
      >
        {steps.map((label) => (
          <Step sx={{ color: "#3BA5B9" }} key={label}>
            <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          height={"50vh"}
        >
          <Typography sx={{ mt: 2, mb: 1, fontSize: "20px" }}>
            Your Consent Submitted Successfully.
          </Typography>
        </Box>
      ) : (
        <Box mt={6}>
          {activeStep === 0 && (
            <Box sx={{ mt: 2, mb: 1 }}>
              <GeneralFirstpage studentData={studentData} />
            </Box>
          )}
          {activeStep === 1 && (
            <Box sx={{ mt: 2, mb: 1 }}>
              <StudentMedicalHistory
                activeStep={activeStep}
                studentMedicalHistory={studentMedicalHistory}
              />
            </Box>
          )}
          {activeStep === 2 && (
            <Box sx={{ mt: 2, mb: 1 }}>
              <FamilyMedicalHistory
                familyMedicalHistory={familyMedicalHistory}
              />
            </Box>
          )}

          <Box sx={{ display: "flex", pt: 2, justifyContent: "end" }}>
            <Button color="inherit" onClick={handleResetForm} sx={{ mr: 1 }}>
              Clear Form
            </Button>

            {activeStep === 0 ? (
              ""
            ) : (
              <Button
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #E4E4E7",
                  mr: 1,
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#000",
                    border: "1px solid #E4E4E7",
                  },
                }}
                variant="outlined"
                onClick={handleBack}
              >
                Back
              </Button>
            )}

            <Button
              onClick={() => {
                if (activeStep === 0) {
                  studentData.handleSubmit();
                } else if (activeStep === 1) {
                  studentMedicalHistory.handleSubmit();
                } else if (activeStep === 2) {
                  familyMedicalHistory.handleSubmit();
                }
              }}
              variant="outlined"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                "&:hover": { backgroundColor: "#3BA5B9", color: "#fff" },
              }}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
