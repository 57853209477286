import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Textfield from "../../Components/Textfield";
import SimpleDropdown from "../../Components/SimpleDropdown";
import { Switch } from "antd";
import { useFormik } from "formik";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";
import * as Yup from "yup";

const AddRoleDrawer = ({
  open,
  handleClose,
  handleOpen,
  selectedData,
  handleUserRoles,
}) => {
  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const theme = useTheme();

  const isClinicVisit =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "clinical_visit")
      : "";

  const isStudentTracking =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find(
          (data) => data.module == "student_tracking"
        )
      : "";

  const iscounsellorTracking =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find(
          (data) => data.module == "counsellor_tracking"
        )
      : "";

  const isParentConsent =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "parent_consent")
      : "";

  const isGeneralConsent =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "general_consent")
      : "";

  const isVaccineConsent =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find(
          (data) => data.module == "vaccination_consent"
        )
      : "";

  const isSettings =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "settings")
      : "";

  const isReport =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "reports")
      : "";

  const isIncident =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "incidents")
      : "";

  const is_notification =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "notification")
      : "";

  const issend_notification =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find(
          (data) => data.module == "send_notification"
        )
      : "";

  const ishealth_check_up =
    selectedData && selectedData.privileges
      ? selectedData.privileges.find((data) => data.module == "health_check_up")
      : "";

  //----------------------------------------------------------

  const vaildation = Yup.object().shape({
    role: Yup.string().required("This field should not be empty"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: vaildation,
    initialValues: {
      role: (selectedData && selectedData.role_id) || "",
      permissions: {
        dashboard: {
          enabled: true,
        },
        clinical_visit: {
          enabled: isClinicVisit ? true : false,
          permission_level: isClinicVisit ? isClinicVisit.permission : "View",
        },
        student_tracking: {
          enabled: isStudentTracking ? true : false,
          permission_level: isStudentTracking
            ? isStudentTracking.permission
            : "View",
        },
        counsellor_tracking: {
          enabled: iscounsellorTracking ? true : false,
          // permission_level: "View",
        },
        parent_consent: {
          enabled: isParentConsent ? true : false,
          permission_level: isParentConsent
            ? isParentConsent.permission
            : "View",
        },
        general_consent: {
          enabled: isGeneralConsent ? true : false,
          //   permission_level: "View",
        },
        vaccination_consent: {
          enabled: isVaccineConsent ? true : false,
          //   permission_level: "View",
        },
        settings: {
          enabled: isSettings ? true : false,
        },
        reports: {
          enabled: isReport ? true : false,
        },
        incidents: {
          enabled: isIncident ? true : false,
        },
        notification: {
          enabled: is_notification ? true : false,
        },
        send_notification: {
          enabled: issend_notification ? true : false,
        },
        health_check_up: {
          enabled: ishealth_check_up ? true : false,
        },
      },
    },
    onSubmit: async (value) => {
      if (selectedData) {
        handleRoleUpdate(value);
      } else {
        try {
          Commonservices.loaderON(dispatch);
          let req = {};
          req = value;
          req.institution_id = userData.institution_id;
          let apiResult = await mysqlservice.post("add_role", req);
          if (apiResult.status == 200) {
            toast.success(apiResult.message);
            handledrawerClose();
            await handleUserRoles();
          } else {
            toast.error(apiResult.message);
          }
          Commonservices.loaderOFF(dispatch);
        } catch (error) {
          Commonservices.loaderON(dispatch);
          console.log(error);
          Commonservices.loaderOFF(dispatch);
        }
      }
    },
    validateOnChange: false,
  });

  const handledrawerClose = () => {
    handleClose();
    formik.resetForm();
  };

  const handleRoleUpdate = async (value) => {
    try {
      Commonservices.loaderON(dispatch);
      let apiResult = await mysqlservice.post("/update_role", value);
      if (apiResult.status == 200) {
        toast.success(apiResult.message);
        handledrawerClose();
        await handleUserRoles();
      } else {
        toast.error(apiResult.message);
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const userroles = [
    { id: 1, name: "Admin", value: "10" },
    { id: 2, name: "Counselor", value: "20" },
    { id: 3, name: "Doctor", value: "30" },
    { id: 4, name: "Nurse", value: "40" },
  ];

  const permissions = [
    { id: 1, name: "Edit", value: "Edit" },
    { id: 2, name: "View", value: "View" },
  ];
  return (
    <Drawer
      open={open}
      onClose={handledrawerClose}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {selectedData ? (
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Update Role
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Add Role
              </Typography>
            )}
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handledrawerClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>
        {/* content */}
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <FormControl size="small">
              <Typography sx={{ fontSize: "14px" }}>
                Role<span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Select Role"
                value={formik.values.role}
                onChange={(e) => {
                  formik.setFieldValue("role", e.target.value);
                  if (e.target.value == "10") {
                    formik.setFieldValue("permissions.settings.enabled", true);
                  } else {
                    formik.setFieldValue("permissions.settings.enabled", false);
                  }
                }}
                sx={{
                  backgroundColor: "#fff",
                  ".MuiSelect-icon": {
                    color: "gray",
                  },
                  ".MuiSelect-select": {
                    color: "#000",
                  },
                }}
                error={formik.errors.role}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <p style={{ color: "gray" }}>Select Role</p>;
                  }
                  const selectedItem = userroles.find(
                    (role) => role.value == selected
                  );
                  return selectedItem ? selectedItem.name : selected;
                }}
              >
                {userroles.map((data, index) => {
                  return (
                    <MenuItem value={data.value} key={index}>
                      {data.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.errors.role ? (
                <Typography sx={{ fontSize: "10px", color: "red", ml: "auto" }}>
                  {formik.errors.role}
                </Typography>
              ) : (
                ""
              )}
            </FormControl>

            <Divider />
            <Typography>Role’s Permissions</Typography>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <Switch
                checked={formik.values.permissions.clinical_visit.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.clinical_visit.enabled",
                    !formik.values.permissions.clinical_visit.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.clinical_visit
                    .enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Clinic Visits</Typography>
              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{
                    width: "86px",
                    color: "#000",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#495057",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#adb5bd", // Border color on hover
                    },
                    ".Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray", // Border color when disabled
                    },
                  }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.clinical_visit.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.clinical_visit.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <Switch
                checked={formik.values.permissions.student_tracking.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.student_tracking.enabled",
                    !formik.values.permissions.student_tracking.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.student_tracking
                    .enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Student Tracking</Typography>
              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{
                    width: "86px",
                    color: "#000",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#495057",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#adb5bd", // Border color on hover
                    },
                    ".Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray", // Border color when disabled
                    },
                  }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.student_tracking.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.student_tracking.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <Switch
                checked={formik.values.permissions.counsellor_tracking.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.counsellor_tracking.enabled",
                    !formik.values.permissions.counsellor_tracking.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.counsellor_tracking
                    .enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Counsellor Tracking</Typography>
              {/* <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{ width: "86px" }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.counsellor_tracking
                      .permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.counsellor_tracking.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box> */}
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                pt: 2,
                pb: 1.5,
                pl: 2,
                pr: 1,
                display: "flex",
                alignItems: "center",
                color: "#000",
              }}
            >
              <Box>
                <Box display={"flex"} gap={2}>
                  <Switch
                    checked={formik.values.permissions.parent_consent.enabled}
                    onChange={() => {
                      formik.setFieldValue(
                        "permissions.parent_consent.enabled",
                        !formik.values.permissions.parent_consent.enabled
                      );
                    }}
                    style={{
                      backgroundColor: formik.values.permissions.parent_consent
                        .enabled
                        ? "#3BA5B9"
                        : "",
                    }}
                  />
                  <Typography fontSize={"14px"}>Parent Consents</Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "gray",
                          },
                        }}
                        checked={
                          formik.values.permissions.general_consent.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.general_consent.enabled",
                            !formik.values.permissions.general_consent.enabled
                          );
                        }}
                      />
                    }
                    label="General Consents"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "gray",
                          },
                        }}
                        checked={
                          formik.values.permissions.vaccination_consent.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.vaccination_consent.enabled",
                            !formik.values.permissions.vaccination_consent
                              .enabled
                          );
                        }}
                      />
                    }
                    label="Vaccination Consents"
                  />
                </Box>
              </Box>

              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{
                    width: "86px",
                    color: "#000",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#495057",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#adb5bd", // Border color on hover
                    },
                    ".Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray", // Border color when disabled
                    },
                  }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.parent_consent.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.parent_consent.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <Switch
                checked={formik.values.permissions.reports.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.reports.enabled",
                    !formik.values.permissions.reports.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.reports.enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Reports</Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <Switch
                checked={formik.values.permissions.incidents.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.incidents.enabled",
                    !formik.values.permissions.incidents.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.incidents.enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Incidents</Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                pt: 2,
                pb: 1.5,
                pl: 2,
                pr: 1,
                display: "flex",
                alignItems: "center",
                color: "#000",
              }}
            >
              <Box>
                <Box display={"flex"} gap={2}>
                  <Switch
                    checked={formik.values.permissions.notification.enabled}
                    onChange={() => {
                      formik.setFieldValue(
                        "permissions.notification.enabled",
                        !formik.values.permissions.notification.enabled
                      );
                    }}
                    style={{
                      backgroundColor: formik.values.permissions.notification
                        .enabled
                        ? "#3BA5B9"
                        : "",
                    }}
                  />
                  <Typography fontSize={"14px"}>Notification</Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "gray",
                          },
                        }}
                        checked={
                          formik.values.permissions.send_notification.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.send_notification.enabled",
                            !formik.values.permissions.send_notification.enabled
                          );
                        }}
                      />
                    }
                    label="Sent Pre/Post Vaccine Notification"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "gray",
                          },
                        }}
                        checked={
                          formik.values.permissions.health_check_up.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.health_check_up.enabled",
                            !formik.values.permissions.health_check_up.enabled
                          );
                        }}
                      />
                    }
                    label="Health Check Up"
                  />
                </Box>
              </Box>

              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{
                    width: "86px",
                    color: "#000",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#495057",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#adb5bd", // Border color on hover
                    },
                    ".Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgray", // Border color when disabled
                    },
                  }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.parent_consent.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.parent_consent.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handledrawerClose}>
              Cancel
            </Button>
            {selectedData ? (
              <Button
                variant="contained"
                sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
                onClick={formik.handleSubmit}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddRoleDrawer;
