import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  TextField,
  Divider,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Gov_Dubai from "../../../Asset/Gov_Dubai.png";
import DubaiHealth from "../../../Asset/DubaiHealth.png";
import { useFormik } from "formik";
import moment from "moment";
import Datefeild from "../../../Components/Datefeild";
import Textfield from "../../../Components/Textfield";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useReactToPrint } from "react-to-print";
import Commonservices from "../../../service/Commonservices";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import mysqlservice from "../../../service/mySqlservice";

const VaccinationConsentReport = () => {
  const ismobilescreen = useMediaQuery("(max-width: 900px)");

  const [vaccineData, setvaccineData] = useState({});
  const vaccineRef = useRef();
  const dispatch = useDispatch();
  const url_parmas = useParams();

  useEffect(() => {
    handleVaccineReport();
  }, []);

  const handleVaccineReport = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = { id: url_parmas.id };
      let apiResult = await mysqlservice.post(
        "get_vaccine_consent_report",
        req
      );
      setvaccineData(apiResult.reportData);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const personalDetaillist = [
    { id: 1, title: "Student Full Name", value: "student_full_name" },
    { id: 2, title: "Gender", value: "gender" },
    { id: 3, title: "Emirates ID", value: "emirates_id" },
    { id: 4, title: "DOB", value: "dob" },
    { id: 6, title: "School Name", value: "school_name" },
    { id: 5, title: "Grade", value: "grade" },
    { id: 7, title: "Year", value: "year" },
  ];

  const studentDetaillist = [
    {
      id: 1,
      title:
        "Has the student had a serious reaction to a vaccine in the past? If yes, please specify.",
      value: "reaction_for_vaccine",
      comment: "reaction_for_vaccine_comment",
    },
    {
      id: 2,
      title:
        "Does the student have any allergies to medication or food (e.g. Eggs, yeast, etc:) or others? If yes, please specify.",
      value: "have_any_allergy",
      comment: "have_any_allergy_comment",
    },
    {
      id: 3,
      title:
        "Does the student suffer from any health conditions that weaken the immune system like cancer, organ transplant, etc.? If yes, specify",
      value: "have_health_condition",
      comment: "have_health_condition_comment",
    },
    {
      id: 4,
      title:
        "Has the student taken steroids, chemotherapy, or radiotherapy in the past 3 months? If yes, specify the date",
      value: "have_taken_steroids",
      comment: "have_taken_steroids_comment",
    },
    {
      id: 5,
      title:
        "Does the student receive any medication that affects blood coagulation? if yes, specify",
      value: "have_medication",
      comment: "have_medication_comment",
    },
    {
      id: 6,
      title:
        "Did the student receive any blood transfusion, antibodies, or plasma within the past year? If yes, specify the date",
      value: "have_blood_transfusion",
      comment: "have_blood_transfusion_comment",
    },
    {
      id: 7,
      title:
        "Does the student currently suffer from any illness/infection disease? If yes, specify",
      value: "have_infection",
      comment: "have_infection_comment",
    },
  ];

  const parentDetail = [
    { id: 1, title: "Parent / Legal Guardian Name", value: "parent_name" },
    { id: 2, title: "Relationship", value: "relationship" },
    // {
    //   id: 3,
    //   title: "Parent / Legal Guardian Signature",
    //   value: "parent_signature",
    // },
  ];

  const disAgreelist = [
    {
      id: 1,
      title:
        "Vaccination dose marked above has been administered previously (please provide the proof).",
      value: "administered_previously",
    },
    {
      id: 2,
      title:
        "Medical condition that prevents from being vaccinated currently (please provide a medical exemption report from the treating physician)",
      value: "condition_for_disagree",
    },
    { id: 3, title: "Other reason (please specify)", value: "other_reason" },
  ];

  let combinedGrade = `${vaccineData.class} - ${vaccineData.batch}`;

  const studentData = useFormik({
    enableReinitialize: true,
    // validationSchema: studentValidation,
    initialValues: {
      student_full_name: vaccineData.student_name || "",
      gender:
        vaccineData.gender === "M" || vaccineData.gender === "m"
          ? "Male"
          : "Female" || "",
      emirates_id: vaccineData.emirates_id || "",
      dob: vaccineData.dob || "",
      school_name: vaccineData.institution_name || "",
      grade: (vaccineData && combinedGrade) || "",
      year: vaccineData.year || "",

      reaction_for_vaccine: vaccineData.reaction_for_vaccine || "",
      reaction_for_vaccine_comment:
        vaccineData.reaction_for_vaccine_comment || "",
      have_any_allergy: vaccineData.have_any_allergy || "",
      have_any_allergy_comment: vaccineData.have_any_allergy_comment || "",
      have_health_condition: vaccineData.have_health_condition || "",
      have_health_condition_comment:
        vaccineData.have_health_condition_comment || "",
      have_taken_steroids: vaccineData.have_taken_steroids || "",
      have_taken_steroids_comment:
        vaccineData.have_taken_steroids_comment || "",
      have_blood_transfusion: vaccineData.have_blood_transfusion || "",
      have_blood_transfusion_comment:
        vaccineData.have_blood_transfusion_comment || "",
      have_medication: vaccineData.have_medication || "",
      have_medication_comment: vaccineData.have_medication_comment || "",
      have_infection: vaccineData.have_infection || "",
      have_infection_comment: vaccineData.have_infection_comment || "",

      have_vaccince: vaccineData.have_vaccince || "",
      prev_vaccine_name: vaccineData.prev_vaccine_name || "",
      prev_vaccine_date: vaccineData.prev_vaccine_date || "",

      have_seizure: vaccineData.have_seizure || "",
      family_disesase: vaccineData.family_disesase || "",

      parent_name: vaccineData.parent_name || "",
      relationship: vaccineData.relationship || "",
      // parent_signature: "",
      declare_Date: moment(vaccineData.declare_Date).format("YYYY-MM-DD") || "",

      agree_consent: vaccineData.agree_consent || "",

      vaccination_date:
        moment(vaccineData.vaccination_date).format("YYYY-MM-DD") || "",
      vaccination_type: vaccineData.vaccination_type || "",
      vaccination_dose: vaccineData.dose || "",

      administered_previously: vaccineData.administered_previously || "",
      condition_for_disagree: vaccineData.condition_for_disagree || "",
      other_reason: vaccineData.other_reason || "",
      other_reason_comment: vaccineData.other_reason_comment || "",
      update_immunisation: vaccineData.update_immunisation || "",
    },
    onSubmit: (values) => {},
  });

  const handlePrint = useReactToPrint({
    content: () => vaccineRef.current,
  });

  return (
    <Box>
      <Box ref={vaccineRef}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "#fff",
            minHeight: "128px",
            boxShadow: "0px 5px 5px #C4C4C417",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              pt: 3,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <img
                src={Gov_Dubai}
                alt=""
                style={
                  ismobilescreen
                    ? { height: "46px", width: "104px" }
                    : { height: "76px", width: "172px" }
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                mt: { xs: 3, md: 0 },
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "14px", md: "20px" }, fontWeight: 600 }}
              >
                PUBLIC HEALTH PROTECTION DEPARTMENT SCHOOL HEALTH SECTION
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "18px" },
                  color: "#4B4B5B",
                  textTransform: "uppercase",
                  mt: { xs: "8px", md: "0px" },
                }}
              >
                Student Medical Form & General Consent
              </Typography>
            </Box>

            <img
              src={DubaiHealth}
              alt=""
              style={
                ismobilescreen
                  ? { height: "46px", width: "104px" }
                  : { height: "76px", width: "172px" }
              }
            />
          </Box>
        </AppBar>
        <Box padding={3}>
          <Box>
            <Box>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Section A: This section should be filled by school Doctor /
                Nurse
              </Typography>
            </Box>

            <Grid container spacing={1} mt={1}>
              {personalDetaillist.map((val, ind) => (
                <Grid item xs={4} md={2.4} key={ind}>
                  <Textfield
                    label={val.title}
                    value={studentData.values[val.value]}
                    isDisable={true}
                  />
                </Grid>
              ))}
            </Grid>

            <Box mt={4}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Section B: This part to be filled & signed by parents or
                guardians
              </Typography>
              <Box mt={2}>
                <Typography
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    fontWeight: 500,
                  }}
                >
                  <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                  To ensure safe vaccination, the school clinic requires
                  information about the student's health status. Please review
                  and complete the following checklist and return it to the
                  school prior to administering the vaccination.
                </Typography>

                <Typography
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    fontWeight: 500,
                    mt: 1,
                  }}
                >
                  <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                  Please inform the school clinic of any change in the student's
                  health status during the academic year, as it might affect the
                  vaccine dose.
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={2} mt={2}>
              {studentDetaillist.map((val, index) => (
                <Grid item xs={3} lg={2.4} key={index}>
                  <Box height={{ xs: "auto", md: "130px", xl: "110px" }}>
                    <Typography
                      sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
                    >
                      {val.title}
                    </Typography>
                    <Box display={"flex"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={studentData.values[val.value]}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={!studentData.values[val.value]}
                          />
                        }
                        label="No"
                      />
                    </Box>
                  </Box>
                  <TextField
                    id={`${index}-enquiry`}
                    sx={{
                      backgroundColor: "#fff",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#DFDFDF",
                        },
                        "&:hover fieldset": {
                          borderColor: "#DFDFDF",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#DFDFDF",
                        },
                        "& input::placeholder": {
                          color: "#71717A",
                        },
                      },
                    }}
                    value={studentData.values[val.comment]}
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
              ))}

              <Grid item xs={3} lg={2.4}>
                <Box>
                  <Typography
                    sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
                  >
                    Last vaccine given to my son/daughter? If yes, specify name
                    of the vaccine taken and date of administration.
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.have_vaccince}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.have_vaccince}
                        />
                      }
                      label="No"
                    />
                  </Box>
                </Box>
                <TextField
                  id={`-enquiry`}
                  sx={{
                    backgroundColor: "#fff",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#DFDFDF",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DFDFDF",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#DFDFDF",
                      },
                      "& input::placeholder": {
                        color: "#71717A",
                      },
                    },
                  }}
                  value={studentData.values.prev_vaccine_name}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
                <TextField
                  id={`-enquiry`}
                  sx={{
                    backgroundColor: "#fff",
                    mt: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#DFDFDF",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DFDFDF",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#DFDFDF",
                      },
                      "& input::placeholder": {
                        color: "#71717A",
                      },
                    },
                  }}
                  value={studentData.values.prev_vaccine_date}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={4} lg={2.4}>
                <Box>
                  <Typography
                    sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
                  >
                    Has the student had a seizure in the past?
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.have_seizure}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.have_seizure}
                        />
                      }
                      label="No"
                    />
                  </Box>
                </Box>

                <Box mt={1}>
                  <Typography
                    sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
                  >
                    Does any household member/ relative living in the same house
                    have cancer, leukemia, organ transplant or any other immune
                    system problem?
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.family_disesase}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.family_disesase}
                        />
                      }
                      label="No"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={1} mt={2}>
              {parentDetail.map((val, ind) => (
                <Grid item xs={4} md={2.4} key={ind}>
                  <Textfield
                    label={val.title}
                    value={studentData.values[val.value]}
                    isDisable={true}
                  />
                </Grid>
              ))}
              <Grid item xs={2.4}>
                <Textfield
                  label={"Date"}
                  value={studentData.values.declare_Date}
                  isDisable={true}
                />
              </Grid>
            </Grid>
          </Box>

          {/* //-------------------- */}
          <Box>
            <Box mt={4} className="second-page">
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Important Notes
              </Typography>
              <Box mt={2} display={"flex"} flexDirection={"column"} gap={0.5}>
                <Typography
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    fontWeight: 500,
                  }}
                >
                  <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                  Please submit the original vaccine card.
                </Typography>

                <Typography
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    fontWeight: 500,
                  }}
                >
                  <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                  Please submit a copy of any medical report related to the
                  student's health, which might affect giving your child the
                  vaccine.
                </Typography>

                <Typography
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    fontWeight: 500,
                  }}
                >
                  <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                  Please inform the school doctor/ nurse if the student received
                  any vaccination outside the school (during the academic year)
                </Typography>

                <Typography
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    fontWeight: 500,
                  }}
                >
                  <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                  Please inform the school doctor/nurse of any changes in the
                  students health condition during the school year and attach
                  the medical report, if any.
                </Typography>
              </Box>
            </Box>

            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Section A: This section should be filled by school Doctor /
                Nurse
              </Typography>
              <Grid container spacing={1} mt={1}>
                {personalDetaillist.map((val, ind) => (
                  <Grid item xs={4} md={2.4} key={ind}>
                    <Textfield
                      label={val.title}
                      value={studentData.values[val.value]}
                      isDisable={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Due Vaccines
              </Typography>
              <Divider sx={{ ml: 2, flexGrow: 1 }} />
            </Box>
            <Box mt={2}>
              <Typography>
                According to the National Immunization Program, the student is
                due for the below marked vaccines as recommended by Dubai Health
                Authority.
              </Typography>
              <Grid container mt={1}>
                <Grid item xs={2.4}>
                  <Textfield
                    label={"Date"}
                    defaultValue={studentData.values.vaccination_date}
                    isDisable={true}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Vaccines
              </Typography>
              <Divider sx={{ ml: 2, flexGrow: 1 }} />
            </Box>

            <Grid container>
              <Grid item xs={6}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Textfield
                    label={"Vaccine"}
                    value={studentData.values.vaccination_type}
                    isDisable={true}
                  />
                  <Textfield
                    label={"Dose No:"}
                    value={studentData.values.vaccination_dose}
                    isDisable={true}
                    style={{ width: "40px" }}
                  />
                  <FormControlLabel
                    control={<Checkbox size="small" defaultChecked />}
                    label="Due"
                    sx={{ transform: "translate(0px,10px)" }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Section B:- This part to be filled & signed by parents or
                guardians
              </Typography>

              <Grid container mt={0.5}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box display={"flex"} alignItems={"start"}>
                    <Checkbox
                      size="small"
                      checked={studentData.values.agree_consent}
                    />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        transform: "translate(0px,10px)",
                      }}
                    >
                      I agree to vaccinate with the vaccines marked above
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box display={"flex"} alignItems={"start"}>
                    <Checkbox
                      size="small"
                      checked={!studentData.values.agree_consent}
                    />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        transform: "translate(0px,10px)",
                      }}
                    >
                      I disagree to administer the vaccines marked above:
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box mt={3}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                If disagree for vaccination, please state the reason:
              </Typography>
              {disAgreelist.map((val, index) => (
                <Box display={"flex"} alignItems={"start"} key={index}>
                  <Checkbox
                    size="small"
                    checked={studentData.values[val.value]}
                  />
                  <Typography
                    sx={{
                      fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      transform: "translate(0px,10px)",
                    }}
                  >
                    {val.title}
                  </Typography>
                </Box>
              ))}
              <Box width={{ xs: "100%", md: "328px" }} ml={{ xs: 0, md: 1 }}>
                <Textfield
                  value={studentData.values.other_reason_comment}
                  isDisable={true}
                />
              </Box>
              <Box display={"flex"} alignItems={"start"}>
                <Checkbox
                  size="small"
                  checked={studentData.values.update_immunisation}
                />
                <Typography
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    transform: "translate(0px,10px)",
                  }}
                >
                  The school medical staff has been provided with an updated
                  immunization record for my son/daughter
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={1} mt={2}>
              {parentDetail.map((val, ind) => (
                <Grid item xs={4} md={2.4} key={ind}>
                  <Textfield
                    label={val.title}
                    value={studentData.values[val.value]}
                    isDisable={true}
                  />
                </Grid>
              ))}
              <Grid item xs={2.4}>
                <Textfield
                  label={"Date"}
                  value={studentData.values.declare_Date}
                  isDisable={true}
                />
              </Grid>
            </Grid>
          </Box>
          {/* //-------------------- */}
        </Box>
      </Box>

      <Box padding={2} display={"flex"} justifyContent={"center"}>
        <Button variant="contained" onClick={handlePrint}>
          Print
        </Button>
      </Box>
    </Box>
  );
};

export default VaccinationConsentReport;
