import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import SearchIcon from "@mui/icons-material/Search";
import Datefeild from "../../Components/Datefeild";
import image from "../../Asset/User.png";
import moment from "moment";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import UpdateDrawer from "../ClinicalVisit/UpdateDrawer";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReplayIcon from "@mui/icons-material/Replay";

const Index = ({ privilege }) => {
  const userData = useSelector((state) => state.user);
  const containerRef = useRef();

  const [sentStudentdata, setsentStudentdata] = useState([]);
  const [marked, setMarked] = useState("");
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [pages, setpages] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");

  const [selectedStudent, setSelectedStudent] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [markedAsRead, setMarkedAsRead] = useState({});

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
    handlesendStudent();
  }, [pages]);

  // useEffect(() => {
  //   const timers = sentStudentdata.map((data) => {
  //     const visitId = `${data.admission_no}-${data.createdAt}`;

  //     if (data.visit_status == "Sent to Clinic" && data.status.length < 2) {
  //       const sentTime = new Date(data.createdAt).getTime();
  //       const currentTime = Date.now();

  //       const timeDiff = currentTime - sentTime;

  //       if (timeDiff < 10 * 60 * 1000) {
  //         const remainingTime = 10 * 60 * 1000 - timeDiff;

  //         const timer = setTimeout(() => {
  //           setMarkedAsRead((prev) => ({
  //             ...prev,
  //             [visitId]: true,
  //           }));
  //         }, remainingTime);

  //         return timer;
  //       } else {
  //         setMarkedAsRead((prev) => ({
  //           ...prev,
  //           [visitId]: true,
  //         }));
  //       }
  //     }
  //     const isBackToClass =
  //       data &&
  //       data.status &&
  //       data.status.find((val) => val.visit_status === "Back to Class");

  //     const isReachedClass =
  //       data &&
  //       data.status &&
  //       data.status.find((val) => val.visit_status === "Reached Class");

  //     if (data.visit_status == "Back to Class") {
  //       const sentTime = new Date(isBackToClass.Added_on).getTime();
  //       const currentTime = Date.now();

  //       const timeDiff = currentTime - sentTime;

  //       if (timeDiff < 10 * 60 * 1000) {
  //         const remainingTime = 10 * 60 * 1000 - timeDiff;

  //         const timer = setTimeout(() => {
  //           setMarkedAsRead((prev) => ({
  //             ...prev,
  //             [visitId]: true,
  //           }));
  //         }, remainingTime);

  //         return timer;
  //       } else {
  //         setMarkedAsRead((prev) => ({
  //           ...prev,
  //           [visitId]: true,
  //         }));
  //       }
  //     }

  //     if (isReachedClass && isReachedClass.visit_status === "Reached Class") {
  //       setMarkedAsRead((prev) => ({
  //         ...prev,
  //         [visitId]: false,
  //       }));
  //     }

  //     return null;
  //   });

  //   return () => {
  //     timers.forEach((time) => clearTimeout(time));
  //   };
  // }, [sentStudentdata]);

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleDrawerOpen = (val) => {
    setSelectedStudent(val);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  let tablehead;
  if (privilege.permission == "Edit") {
    tablehead = [
      "Sl",
      "Name & Batch",
      "Sent By",
      "Date",
      "Off to Clinic",
      "Reached Time",
      "Status",
      "Off to Class",
      "Reached Time",
      "Action",
    ];
  } else {
    tablehead = [
      "Sl",
      "Name & Batch",
      "Sent By",
      "Date",
      "Off to Clinic",
      "Reached Time",
      "Status",
      "Off to Class",
      "Reached Time",
    ];
  }

  const handlesendStudent = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        searchKey: search,
        date: selectedDate,
        page: pages,
        pageCount: rowsPerPage,
      };

      const apiResult = await mysqlservice.post("track_visits", req);
      setsentStudentdata(apiResult.data);
      setTotalCount(apiResult.totalCount);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleReachedStudent = async (value) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        id: value.id,
        sent_by: userData.name,
        sent_by_id: userData.user_id,
        // sent_by_token: userData.token,
        app_type: "clinic",
      };
      const apiResult = await mysqlservice.post("markvisits", req);
      toast.success("Student marked as reached");
      let valId = `${value.admission_no}-${value.createdAt}`;
      setMarkedAsRead((prev) => ({
        ...prev,
        [valId]: false,
      }));
      handlesendStudent();
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleSearchData = (value) => {
    let searchTerm = value;
    setSearch(searchTerm);
  };

  const handleDateSelect = (date, dateString) => {
    if (date) {
      const newDate = dayjs(date);
      setSelectedDate(newDate);
    } else {
      setSelectedDate("");
    }
  };

  const handleFilter = () => {
    handlesendStudent();
  };

  const handlePageChange = (event, value) => {
    setpages(value);
  };

  const handleScrolll = (scrollAmt) => {
    containerRef.current.scrollLeft += scrollAmt;
  };

  const handleReload = ()=>{
    window.location.reload()
  }

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "STUDENT TRACKING",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box p={2}>
        {/* <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#3BA5B9",
            color: "#fff",
            fontSize: "14px",
            fontWeight: 500,
            height: "48px",
            textTransform: "uppercase",
            // width: 250,
            mt: 0.5,
            display: "flex",
            ml: "auto",
          }}
        >
          <ImUserPlus style={{ height: "20px", width: "20px" }} /> &nbsp; Add
          Student
        </Button> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "end",
          }}
        >
          <Typography sx={{ fontSize: "12px", color: "red" }}>
            Please refresh to load the latest data.
          </Typography>
          <IconButton
            sx={{
              borderRadius: "4px",
              border: "1px solid gray",
              height: "20px",
              width: "20px",
            }}
            onClick={handleReload}
          >
            <ReplayIcon sx={{ fontSize: "15px" }} />
          </IconButton>
        </Box>
        <Card sx={{ mt: 1, p: 1 }}>
          <Box
            sx={{ display: { xs: "block", md: "flex" }, alignItems: "center" }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 500, pl: 1 }}>
              Student Tracking List
            </Typography>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                alignItems: "center",
                ml: "auto",
                gap: 1,
              }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                placeholder="Search..."
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: 20, color: "#878A99" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: { xs: "100%", md: "auto" },
                  backgroundColor: "white",
                  borderRadius: "5px",
                  border: "1px solid #DFDFDF",
                  color: "gray",
                  mt: { xs: 1, md: 0 },
                  mb: { xs: 1, md: 0 },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& input::placeholder": {
                    color: "#000",
                  },
                  "& input": {
                    color: "#000",
                  },
                }}
                onChange={(e) => {
                  handleSearchData(e.target.value);
                }}
              />

              <Datefeild
                value={selectedDate}
                onChange={handleDateSelect}
                style={{
                  width: { xs: "100%", md: "auto" },
                }}
              />

              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  //   height: "48px",
                  textTransform: "capitalize",
                  mt: { xs: 1, md: 0 },
                  width: { xs: "100%", md: "auto" },
                }}
                onClick={handleFilter}
              >
                Apply
              </Button>
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            ref={containerRef}
            sx={{
              boxShadow: "0px 0px 2px black",
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  {/* <TableCell>
                    <Checkbox
                      size="small"
                      //   checked={allChecked}
                      //   onChange={(e) => {
                      //     handleAllChecked(e.target.checked);
                      //   }}
                    />
                  </TableCell> */}
                  {tablehead.map((data, index) => {
                    let bgc =
                      theme.palette.mode == "dark" ? "#343a40" : "#F3F6F9";
                    return (
                      <TableCell
                        sx={
                          index == tablehead.length - 1
                            ? {
                                fontSize: { md: "12px", xl: "14px" },
                                fontWeight: 500,
                                position: "sticky",
                                right: 0,
                                backgroundColor: bgc,
                              }
                            : {
                                fontSize: { md: "12px", xl: "14px" },
                                fontWeight: 500,
                                whiteSpace: "nowrap",
                              }
                        }
                        key={index}
                      >
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sentStudentdata && sentStudentdata.length ? (
                  sentStudentdata.map((val, index) => {
                    let sl_no = index + (pages - 1) * rowsPerPage;

                    const offToClinic =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Sent to Clinic"
                      );
                    const reachedClinic =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Reached Clinic"
                      );
                    const offToClass =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Back to Class"
                      );
                    const reachedClass =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Reached Class"
                      );

                    const visitId = `${val.admission_no}-${val.createdAt}`;
                    return (
                      <TableRow
                        key={index}
                        className={`${markedAsRead[visitId] ? "blink" : ""}`}
                      >
                        {/* <TableCell>
                          <Checkbox
                            size="small"
                            //   checked={allChecked}
                            //   onChange={(e) => {
                            //     handleAllChecked(e.target.checked);
                            //   }}
                          />
                        </TableCell> */}
                        <TableCell align="left" sx={{ color: "#915ECC" }}>
                          {sl_no < 9 ? 0 : ""}
                          {sl_no + 1}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Avatar
                              src={val.profile_pic}
                              sx={{ height: "32px", width: "32px" }}
                            />
                            <Box>
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: 500 }}
                              >
                                {val.student_name}
                              </Typography>
                              <Typography sx={{ fontSize: "13px" }}>
                                Grade {val.class}-{val.batch}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClinic && offToClinic.sent_by
                            ? offToClinic.sent_by
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClinic && offToClinic.Added_on
                            ? moment(offToClinic.Added_on).format(
                                "MMMM D, YYYY"
                              )
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClinic && offToClinic.Added_on
                            ? moment(offToClinic.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {reachedClinic && reachedClinic.Added_on
                            ? moment(reachedClinic.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {reachedClass && reachedClass.visit_status
                            ? reachedClass.visit_status
                            : val.visit_status
                            ? val.visit_status
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClass && offToClass.Added_on
                            ? moment(offToClass.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {reachedClass && reachedClass.Added_on
                            ? moment(reachedClass.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        {privilege.permission == "Edit" ? (
                          <TableCell
                            sx={
                              theme.palette.mode == "dark"
                                ? {
                                    position: "sticky",
                                    right: 0,
                                    backgroundColor: "#343a40",
                                    width: "fit-content",
                                  }
                                : {
                                    position: "sticky",
                                    right: 0,
                                    backgroundColor: "#F3F6F9",
                                    width: "fit-content",
                                  }
                            }
                          >
                            <Tooltip title="Scroll left" placement="top">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: -8,
                                  top: 15,
                                  backgroundColor: "#fff",
                                  border: "1px solid gray",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() => handleScrolll(400)}
                              >
                                <KeyboardArrowRightIcon
                                  style={{ fontSize: "20px", color: "gray" }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Scroll Right">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: -8,
                                  top: 35,
                                  backgroundColor: "#fff",
                                  border: "1px solid gray",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() => handleScrolll(-400)}
                              >
                                <KeyboardArrowLeftIcon
                                  style={{ fontSize: "20px", color: "gray" }}
                                />
                              </IconButton>
                            </Tooltip>
                            {val.visit_status === "Sent to Clinic" ? (
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  backgroundColor: "#3BA5B9",
                                  color: "#fff",
                                  fontSize: "13px",
                                  fontWeight: 500,
                                  // height: "48px",
                                  textTransform: "capitalize",
                                  whiteSpace: "nowrap",
                                  width: "100%",
                                }}
                                onClick={() => {
                                  handleReachedStudent(val);
                                }}
                                // disabled={
                                //   val.visit_status === "Sent to Clinic"
                                //     ? false
                                //     : true
                                // }
                              >
                                Mark as Reached
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  backgroundColor: "#3BA5B9",
                                  color: "#fff",
                                  fontSize: "13px",
                                  fontWeight: 500,
                                  width: "100%",
                                  // height: "48px",
                                  textTransform: "capitalize",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() => {
                                  // handleReachedStudent(val);
                                  handleDrawerOpen(val);
                                }}
                              >
                                Update
                              </Button>
                            )}
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      No data to show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{
              m: 1,
              display: "flex",
              justifyContent: "end",
              "& .Mui-selected": {
                color: "#fff",
                backgroundColor: "#3BA5B9",
              },
              "& .MuiPaginationItem-page": {
                color: "#3BA5B9",
                "&.Mui-selected": {
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                },
              },
            }}
            count={pageCount}
            page={pages}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Card>
      </Box>
      <UpdateDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        updateId={selectedStudent}
        setUpdateId={setMarked}
        handleStudentData={handlesendStudent}
      />
    </>
  );
};

export default Index;
