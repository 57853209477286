import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Textfield from "../../Components/Textfield";
import SimpleDropdown from "../../Components/SimpleDropdown";
import { Switch } from "antd";
import { useFormik } from "formik";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const AddUserDrawer = ({
  open,
  handleClose,
  handleOpen,
  setrefresh,
  selectedData,
  isupdate,
}) => {
  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const theme = useTheme();

  const userValidation = Yup.object().shape({
    name: Yup.string().required("This field should not be empty"),
    email: Yup.string().required("This field should not be empty"),
    role: Yup.string().required("This field should not be empty"),
    password: Yup.string().required("This field should not be empty"),
    c_password: Yup.string().required("This field should not be empty"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userValidation,
    initialValues: {
      name: (selectedData && selectedData.name) || "",
      email: (selectedData && selectedData.email) || "",
      mobile: (selectedData && selectedData.phone) || "",
      role: (selectedData && selectedData.role) || "",
      password: "",
      c_password: "",
    },
    onSubmit: async (value) => {
      if (selectedData && selectedData.id) {
        if (formik.values.password === formik.values.c_password) {
          try {
            Commonservices.loaderON(dispatch);
            let req = {};
            req = value;
            req.id = selectedData.id;
            let apiResult = await mysqlservice.post("update_user", req);
            toast.success("User updated successfully");
            handledrawerClose();
            setrefresh(true);
            Commonservices.loaderOFF(dispatch);
          } catch (error) {
            Commonservices.loaderON(dispatch);
            console.log(error);
            Commonservices.loaderOFF(dispatch);
          }
        } else {
          toast.error("Password and Confirm Password do not matc");
        }
      } else {
        if (formik.values.password === formik.values.c_password) {
          try {
            Commonservices.loaderON(dispatch);
            let req = {};
            req = value;
            req.institution_id = userData.institution_id;
            req.user_id = userData.user_id;
            let apiResult = await mysqlservice.post("adduser", req);
            if (apiResult.status == 200) {
              toast.success(apiResult.message);
              handledrawerClose();
              setrefresh(true);
            } else {
              toast.error(apiResult.message);
            }
            Commonservices.loaderOFF(dispatch);
          } catch (error) {
            Commonservices.loaderON(dispatch);
            console.log(error);
            Commonservices.loaderOFF(dispatch);
          }
        } else {
          toast.error("Password and Confirm Password do not matc");
        }
      }
    },
    validateOnChange: false,
  });

  const handledrawerClose = () => {
    handleClose();
    formik.resetForm();
  };

  const userroles = [
    { id: 1, name: "", value: "" },
    { id: 2, name: "Admin", value: 10 },
    { id: 3, name: "Counselor", value: 20 },
    { id: 4, name: "Doctor", value: 30 },
    { id: 5, name: "Nurse", value: 40 },
  ];

  // const permissions = [
  //   { id: 1, name: "Edit", value: "Edit" },
  //   { id: 2, name: "View", value: "View" },
  // ];

  return (
    <Drawer
      open={open}
      onClose={handledrawerClose}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
                borderRadius: 0,
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
                borderRadius: 0,
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {isupdate ? (
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Edit User
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Add User
              </Typography>
            )}
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handledrawerClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>
        {/* content */}
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Textfield
              label={"Name"}
              placeholder={"Enter Name"}
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
              }}
              error={formik.errors.name}
              isRequired={true}
              helperText={formik.errors.name}
            />
            <Textfield
              label={"Email"}
              placeholder={"Enter Email"}
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
              }}
              error={formik.errors.email}
              isRequired={true}
              helperText={formik.errors.email}
            />
            <Textfield
              label={"Mobile Number"}
              placeholder={"Enter Number"}
              value={formik.values.mobile}
              onChange={(e) => {
                formik.setFieldValue("mobile", e.target.value);
              }}
              type={"number"}
            />
            {/* <Typography sx={{ fontSize: "12px", color: "#495057" }}>
              Your new user will get an email with a Benchmark Clinic link to
              create a username and password.
            </Typography> */}
            <Divider />

            <FormControl size="small">
              <Typography sx={{ fontSize: "14px" }}>
                Role <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.role}
                onChange={(e) => {
                  formik.setFieldValue("role", e.target.value);
                }}
                sx={{
                  backgroundColor: "#fff",
                  ".MuiSelect-icon": {
                    color: "gray",
                  },
                  ".MuiSelect-select": {
                    color: "#000",
                  },
                }}
                error={formik.errors.role}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <p style={{ color: "gray" }}>Select Role</p>;
                  } else {
                    const selectedItem = userroles.find(
                      (role) => role.value == selected
                    );
                    return selectedItem ? selectedItem.name : selected;
                  }
                }}
              >
                {userroles.map((data, index) => {
                  return (
                    <MenuItem value={data.value} key={index}>
                      {data.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.errors.role ? (
                <Typography sx={{ fontSize: "10px", color: "red", ml: "auto" }}>
                  {formik.errors.role}
                </Typography>
              ) : (
                ""
              )}
            </FormControl>

            <Textfield
              label={"Password"}
              value={formik.values.password}
              placeholder={"Enter Password"}
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#344054",
              }}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value);
              }}
              error={formik.errors.password}
              isRequired={true}
              helperText={formik.errors.password}
            />

            <Textfield
              label={"Confirm Password"}
              value={formik.values.c_password}
              placeholder={"Enter Confirm Password"}
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#344054",
              }}
              onChange={(e) => {
                formik.setFieldValue("c_password", e.target.value);
              }}
              error={formik.errors.c_password}
              isRequired={true}
              helperText={formik.errors.c_password}
            />

            {/* <Divider /> */}
            {/* <Typography>Role’s Permissions</Typography>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Switch
                checked={formik.values.permissions.clinical_visit.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.clinical_visit.enabled",
                    !formik.values.permissions.clinical_visit.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.clinical_visit
                    .enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Clinic Visits</Typography>
              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{ width: "86px" }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.clinical_visit.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.clinical_visit.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Switch
                checked={formik.values.permissions.student_tracking.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.student_tracking.enabled",
                    !formik.values.permissions.student_tracking.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.student_tracking
                    .enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Student Tracking</Typography>
              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{ width: "86px" }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.student_tracking.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.student_tracking.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                pt: 2,
                pb: 1.5,
                pl: 2,
                pr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Box display={"flex"} gap={2}>
                  <Switch
                    checked={formik.values.permissions.parent_consent.enabled}
                    onChange={() => {
                      formik.setFieldValue(
                        "permissions.parent_consent.enabled",
                        !formik.values.permissions.parent_consent.enabled
                      );
                    }}
                    style={{
                      backgroundColor: formik.values.permissions.parent_consent
                        .enabled
                        ? "#3BA5B9"
                        : "",
                    }}
                  />
                  <Typography fontSize={"14px"}>Parent Consents</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                        }}
                        checked={
                          formik.values.permissions.general_consent.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.general_consent.enabled",
                            !formik.values.permissions.general_consent.enabled
                          );
                        }}
                      />
                    }
                    label="General Consents"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                        }}
                        checked={
                          formik.values.permissions.vaccination_consent.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.vaccination_consent.enabled",
                            !formik.values.permissions.vaccination_consent
                              .enabled
                          );
                        }}
                      />
                    }
                    label="Vaccination Consents"
                  />
                </Box>
              </Box>

              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{ width: "86px" }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.parent_consent.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.parent_consent.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Switch
                checked={formik.values.permissions.reports.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.reports.enabled",
                    !formik.values.permissions.reports.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.reports.enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Reports</Typography>
            </Box> */}
          </Box>
        </Box>
        {/* footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handledrawerClose}>
              Cancel
            </Button>
            {isupdate ? (
              <Button
                variant="contained"
                sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
                onClick={formik.handleSubmit}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddUserDrawer;
