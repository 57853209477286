import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Gov_Dubai from "../../Asset/Gov_Dubai.png";
import DubaiHealth from "../../Asset/DubaiHealth.png";
import StepperComp from "./StepperComp";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import Commonservices from "../../service/Commonservices";
import { useDispatch } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";

const GeneralConsentForm = () => {
  const ismobilescreen = useMediaQuery("(max-width: 900px)");
  const [activeStep, setActiveStep] = React.useState(0);
  const [fullData, setFullData] = useState({});
  const url_params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [consentData, setConsentData] = useState({});

  useEffect(() => {
    getconsentData();
  }, []);

  const getconsentData = async () => {
    try {
      Commonservices.loaderON(dispatch);

      let req = {
        id: url_params.id,
        token: url_params.token,
      };
      const apiResult = await mysqlservice.post("get_consent", req);
      if (apiResult.status == 200) {
        setConsentData(apiResult.result);
      } else {
        setConsentData({});
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const studentValidation = Yup.object().shape({
    relation: Yup.string().required("This field must not be blank."),
    phone2: Yup.string()
      .required("This field must not be blank.")
      .min(10, "Phone number must be 10 digits.")
      .max(10, "Phone number only be 10 digits."),
    parent2_name: Yup.string().required("This field must not be blank."),
    parent2_relation: Yup.string().required("This field must not be blank."),
    parent2_phone: Yup.string()
      .required("This field must not be blank.")
      .min(10, "Phone number must be 10 digits.")
      .max(10, "Phone number only be 10 digits."),
  });

  const familyValidation = Yup.object().shape({
    declare_Date: Yup.string().required("This field must not be blank."),
  });

  const studentData = useFormik({
    enableReinitialize: true,
    validationSchema: studentValidation,
    initialValues: {
      profile_pic: consentData.profile_pic || "",
      school_name: consentData.institution_name || "",
      grade: consentData.class || "",
      section: consentData.batch || "",
      student_name: consentData.student_name || "",
      gender:
        consentData.gender === "M" || consentData.gender === "m"
          ? "Male"
          : "Female" || "",
      dob: consentData.dob || "",
      nationality: consentData.nationality || "",
      parent_name: consentData.parent_name || "",
      relation: "",
      phone1: consentData.parent_mobile || "",
      phone2: "",
      parent2_name: "",
      parent2_relation: "",
      parent2_phone: "",
      haveEmiratesId: consentData.emirates_id ? true : false,
      emiratesId: consentData.emirates_id ? consentData.emirates_id : "",
      haveHealthCard: false,
      healthcardNumber: "",
      havePassportCopy: false,
      havevaccineCard: false,
      haveHealthInsurance: false,
    },
    onSubmit: (values) => {
      setFullData(values);
      handleNext();
    },
    validateOnChange: false,
  });

  const studentMedicalHistory = useFormik({
    enableReinitialize: true,
    initialValues: {
      have_Cardiovascular_problem: false,
      have_Diabetes: false,
      have_Hypertension: false,
      have_Bronchial_Asthma: false,
      have_Renal_problem: false,
      have_Epilepsy: false,
      have_Hemolytic_Anemia: false,
      have_Poliomyelitis: false,
      have_plasma_transfusion: false,
      have_Skin_Problem: false,
      have_Epistaxis: false,
      have_Viral_Hepatitis: false,

      have_allergy: false,
      have_bloodDisease: false,
      have_medicalAid_device: false,
      have_surgery: false,
      if_Hospitalizes: false,
      have_cancer: false,
      suffer_disease: false,
      have_Behavioral_Problem: false,
      have_other_problem: false,

      have_allergy_comment: "",
      have_bloodDisease_comment: "",
      have_medicalAid_device_comment: "",
      have_surgery_comment: "",
      if_Hospitalizes_comment: "",
      have_cancer_comment: "",
      suffer_disease_comment: "",
      have_Behavioral_Problem_comment: "",
      have_other_problem_comment: "",
    },
    validationSchema: "",
    onSubmit: (values) => {
      setFullData((prev) => ({
        ...prev,
        ...values,
      }));
      handleNext();
    },
  });

  const familyMedicalHistory = useFormik({
    enableReinitialize: true,
    validationSchema: familyValidation,
    initialValues: {
      family_diabetes: false,
      family_cancer: false,
      family_immune_problem: false,
      family_mental_problem: false,
      family_other_problem: false,

      blood_disease: false,
      blood_disease_comment: "",
      allergy_to_food: false,
      allergy_to_food_comment: "",

      declare_valid_info: false,
      agree_health_services: true,

      declare_parent_name: consentData.parent_name || "",
      declare_Relationship: "",
      // declare_Parent_Signature: "",
      declare_Date: "",
    },
    onSubmit: (values) => {
      let result = {};
      result = { ...fullData, ...values };
      result.consent_id = url_params.id;
      handleSaveConsent(result);
    },
    validateOnChange: false,
  });

  const handleSaveConsent = async (val) => {
    try {
      Commonservices.loaderON(dispatch);
      let apiResult = await mysqlservice.post("save_consent", val);
      if (apiResult.status == 200) {
        toast.success(apiResult.message);
        handleNext();
      } else {
        toast.error(apiResult.message);
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#fff",
          minHeight: "128px",
          boxShadow: "0px 5px 5px #C4C4C417",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            alignItems: "center",
            justifyContent: { xs: "center", md: "space-between" },
            px: 2,
            pt: 3,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={Gov_Dubai}
              alt=""
              style={
                ismobilescreen
                  ? { height: "46px", width: "104px" }
                  : { height: "76px", width: "172px" }
              }
            />
            {ismobilescreen ? (
              <img
                src={DubaiHealth}
                alt=""
                style={
                  ismobilescreen
                    ? { height: "46px", width: "104px" }
                    : { height: "76px", width: "172px" }
                }
              />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              mt: { xs: 3, md: 0 },
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "14px", md: "20px" }, fontWeight: 600 }}
            >
              PUBLIC HEALTH PROTECTION DEPARTMENT SCHOOL HEALTH SECTION
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "18px" },
                color: "#4B4B5B",
                textTransform: "uppercase",
                mt: { xs: "8px", md: "0px" },
              }}
            >
              Student Medical Form & General Consent
            </Typography>
          </Box>
          {ismobilescreen ? (
            ""
          ) : (
            <img
              src={DubaiHealth}
              alt=""
              style={
                ismobilescreen
                  ? { height: "46px", width: "104px" }
                  : { height: "76px", width: "172px" }
              }
            />
          )}
        </Box>
      </AppBar>
      <Box>
        <StepperComp
          studentData={studentData}
          studentMedicalHistory={studentMedicalHistory}
          familyMedicalHistory={familyMedicalHistory}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Box>
    </Box>
  );
};

export default GeneralConsentForm;
