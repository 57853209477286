import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AsideImg1 from "../../Asset/loginaside1.png";
import AsideImg2 from "../../Asset/loginaside2.png";

const Aside = () => {
  const AsideData = [
    {
      id: 1,
      image: AsideImg1,
      head1: "Empowering student health, one click at a time.",
      title:
        "With intuitive tools and seamless access, we're here to support school healthcare professionals every step of the way.",
    },
    {
      id: 2,
      image: AsideImg2,
      head1: "Manage school health with ease and precision.",
      title:
        "Easily track student wellness. Ensure timely care with precision.",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % AsideData.length);
    }, 5000);

    return () => clearTimeout(intervalId);
  }, [currentIndex]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Box>
        <img src={AsideData[currentIndex].image} alt="" />
      </Box>
      <Typography
        sx={{
          fontSize: { md: "13px", lg: "19px", xl: "22px" },
          fontWeight: "600",
        }}
      >
        {AsideData[currentIndex].head1}
      </Typography>
      <Typography
        sx={{
          fontSize: { md: "11px", lg: "16px", xl: "20px" },
          width: "80%",
          color: "#E1E1E1",
        }}
      >
        {AsideData[currentIndex].title}
      </Typography>
    </Box>
  );
};

export default Aside;
