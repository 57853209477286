import {
  AppBar,
  Box,
  Button,
  Typography,
  useMediaQuery,
  Avatar,
  Checkbox,
  FormControlLabel,
  Grid,
  Divider,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import GeneralFirstpage from "../GeneralconsentForm/GeneralFirstpage";
import { useFormik } from "formik";
import Gov_Dubai from "../../../Asset/Gov_Dubai.png";
import DubaiHealth from "../../../Asset/DubaiHealth.png";
import StudentMedicalHistory from "../GeneralconsentForm/StudentMedicalHistory";
import FamilyMedicalHistory from "../GeneralconsentForm/FamilyMedicalHistory";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import mysqlservice from "../../../service/mySqlservice";
import Commonservices from "../../../service/Commonservices";
import { useDispatch } from "react-redux";
import Textfield from "../../../Components/Textfield";
import Datefeild from "../../../Components/Datefeild";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";

const GeneralConsentReport = () => {
  const [consentData, setConsentData] = useState({});
  const consentRef = useRef();
  const ismobilescreen = useMediaQuery("(max-width: 900px)");
  const url_params = useParams();
  const dispatch = useDispatch();

  const diseaseList = [
    {
      id: 1,
      title: "Does the student suffer from any Cardiovascular problem? ",
      value: "have_Cardiovascular_problem",
    },
    {
      id: 2,
      title: "Does the student suffer from Diabetes?",
      value: "have_Diabetes",
    },
    {
      id: 3,
      title: "Does the student suffer from Hypertension? ",
      value: "have_Hypertension",
    },
    {
      id: 4,
      title: "Does the student suffer from Bronchial Asthma?",
      value: "have_Bronchial_Asthma",
    },
    {
      id: 5,
      title: "Does the student suffer from any Renal problem?",
      value: "have_Renal_problem",
    },
    {
      id: 6,
      title: "Does the student suffer from Epilepsy or Convulsion seizures?",
      value: "have_Epilepsy",
    },
    {
      id: 7,
      title: "Does the student suffer from Hemolytic Anemia, type G6PD?",
      value: "have_Hemolytic_Anemia",
    },
    {
      id: 8,
      title:
        "Did the student suffer from Poliomyelitis (infantile paralysis infection)?",
      value: "have_Poliomyelitis",
    },
    {
      id: 9,
      title:
        "Did student get any blood, antibodies or plasma transfusion in the past?",
      value: "have_plasma_transfusion",
    },
    {
      id: 10,
      title: "Does the student suffer from any Skin Problem?",
      value: "have_Skin_Problem?",
    },
    {
      id: 11,
      title: "Does the student suffer from Epistaxis??",
      value: "have_Epistaxis",
    },
    {
      id: 12,
      title: "Did the student suffer from Viral Hepatitis?",
      value: "have_Viral_Hepatitis",
    },
  ];

  const enquires = [
    {
      id: 1,
      title:
        "Does the student suffer from any allergy to food, dust, medicine etc.? If yes, please specify in comments",
      value: "have_allergy",
      comment: "have_allergy_comment",
    },
    {
      id: 2,
      title:
        "Does the student suffer from any Hereditary Blood Disease & (e.g. Thalassemia,sickle cell anemia, Hemophilia)?If yes, specify in comments.",
      value: "have_bloodDisease",
      comment: "have_bloodDisease_comment",
    },
    {
      id: 3,
      title:
        "Dose the student use any medical aid device?If yes, specify the device details comments.",
      value: "have_medicalAid_device",
      comment: "have_medicalAid_device_comment",
    },
    {
      id: 4,
      title:
        "Did the student undergo any surgery in the past?If yes, specify the details in comments.",
      value: "have_surgery",
      comment: "have_surgery_comment",
    },
    {
      id: 5,
      title:
        "Was the student ever hospitalized? If yes, please specify the reasons in comments",
      value: "if_Hospitalizes",
      comment: "if_Hospitalizes_comment",
    },
    {
      id: 6,
      title:
        "Does the student have any health condition that could weaken the immune system such as Cancer (Blood cancer, Lymphoma), or an organ transplant? If yes, please specify in comments",
      value: "have_cancer",
      comment: "have_cancer_comment",
    },
    {
      id: 7,
      title:
        "Did the student suffer from any of the following diseases: (Mumps, Measles, Diphtheria, Pertussis, Chickenpox, Tuberculosis),If yes, please specify details in comments",
      value: "suffer_disease",
      comment: "suffer_disease_comment",
    },
    {
      id: 8,
      title:
        "Does the student suffer from any Mental or Behavioral Problem? If yes, please specify in comments",
      value: "have_Behavioral_Problem",
      comment: "have_Behavioral_Problem_comment",
    },
    {
      id: 9,
      title:
        "Does the student suffer from any other Problem or disease not mentioned here? If ease specify in comments",
      value: "have_other_problem",
      comment: "have_other_problem_comment",
    },
  ];

  const DiseaseList = [
    { id: 1, title: "Diabetes", value: "family_diabetes" },
    { id: 2, title: "Any type of Cancer", value: "family_cancer" },
    {
      id: 3,
      title: "Any immune System problem",
      value: "family_immune_problem",
    },
    {
      id: 4,
      title: "Any Mental Health problem",
      value: "family_mental_problem",
    },
    {
      id: 5,
      title: "Others, please specify in comments",
      value: "family_other_problem",
    },
  ];

  const sufferings = [
    {
      id: 1,
      title:
        "Does the student suffer from any Hereditary Blood Disease & (e.g. Thalassemia, sickle cell anemia, Hemophilia)? If yes, specify in comments.",
      value: "blood_disease",
      comment: "blood_disease_comment",
    },
    {
      id: 2,
      title:
        "Does the student suffer from any allergy to food, dust, medicine etc.? If yes, please specify in comments",
      value: "allergy_to_food",
      comment: "allergy_to_food_comment",
    },
  ];

  const parentDeclarationList = [
    {
      id: 2,
      title: "",
      value: "",
    },
    {
      id: 3,
      title: "",
      value: "agree_health_services",
    },
  ];

  const parentDetailList = [
    {
      id: 2,
      title: "Parent / Legal Guardian Name",
      value: "declare_parent_name",
    },
    {
      id: 2,
      title: "Relationship",
      value: "declare_Relationship",
    },
    // {
    //   id: 3,
    //   title: "Parent / Legal Guardian Signature",
    //   value: "declare_Parent_Signature",
    // },
  ];

  useEffect(() => {
    handleGeneralConsentData();
  }, []);

  const handleGeneralConsentData = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = { id: url_params.id };
      let apiResult = await mysqlservice.post("get_consent_report", req);
      setConsentData(apiResult.reportData);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
      return error;
    }
  };

  const studentData = useFormik({
    enableReinitialize: true,
    initialValues: {
      profile_pic: consentData.profile_pic || "",
      school_name: consentData.institution_name || "",
      grade: consentData.class || "",
      section: consentData.batch || "",
      student_name: consentData.student_name || "",
      gender:
        consentData.gender === "M" || consentData.gender === "m"
          ? "Male"
          : "Female" || "",
      dob: consentData.dob || "",
      nationality: consentData.nationality || "",
      parent_name: consentData.parent_name || "",
      relation: consentData.relation || "",
      phone1: consentData.parent_mobile || "",
      phone2: consentData.phone2 || "",
      parent2_name: consentData.parent2_name || "",
      parent2_relation: consentData.parent2_relationship || "",
      parent2_phone: consentData.parent2_phone || "",
      haveEmiratesId: consentData.have_emirates_id || "",
      emiratesId: consentData.emirates_id || "",
      haveHealthCard: consentData.have_health_card || "",
      healthcardNumber: consentData.health_card_number || "",
      havePassportCopy: consentData.have_passport_copy || "",
      havevaccineCard: consentData.have_vaccine_card || "",
      haveHealthInsurance: consentData.have_health_insurance || "",
    },
    onSubmit: (values) => {},
  });

  const studentMedicalHistory = useFormik({
    enableReinitialize: true,
    initialValues: {
      have_Cardiovascular_problem:
        consentData.have_cardiovascular_problem || "",
      have_Diabetes: consentData.have_diabetes || "",
      have_Hypertension: consentData.have_hypertension || "",
      have_Bronchial_Asthma: consentData.have_bronchial_asthma || "",
      have_Renal_problem: consentData.have_renal_problem || "",
      have_Epilepsy: consentData.have_epilepsy || "",
      have_Hemolytic_Anemia: consentData.have_hemolytic_anemia || "",
      have_Poliomyelitis: consentData.have_poliomyelitis || "",
      have_plasma_transfusion: consentData.have_plasma_transfusion || "",
      have_Skin_Problem: consentData.have_skin_problem || "",
      have_Epistaxis: consentData.have_epistaxis || "",
      have_Viral_Hepatitis: consentData.have_viral_hepatitis || "",

      have_allergy: consentData.have_allergy || "",
      have_bloodDisease: consentData.have_blood_disease || "",
      have_medicalAid_device: consentData.have_medical_aid_device || "",
      have_surgery: consentData.have_surgery || "",
      if_Hospitalizes: consentData.if_hospitalized || "",
      have_cancer: consentData.have_cancer || "",
      suffer_disease: consentData.suffer_disease || "",
      have_Behavioral_Problem: consentData.have_behavioral_problem || "",
      have_other_problem: consentData.have_other_problem || "",

      have_allergy_comment: consentData.have_allergy_comment || "",
      have_bloodDisease_comment: consentData.have_blood_disease_comment || "",
      have_medicalAid_device_comment:
        consentData.have_medical_aid_device_comment || "",
      have_surgery_comment: consentData.have_surgery_comment || "",
      if_Hospitalizes_comment: consentData.if_hospitalized_comment || "",
      have_cancer_comment: consentData.have_cancer_comment || "",
      suffer_disease_comment: consentData.suffer_disease_comment || "",
      have_Behavioral_Problem_comment:
        consentData.have_behavioral_problem_comment || "",
      have_other_problem_comment: consentData.have_other_problem_comment || "",
    },
    validationSchema: "",
    onSubmit: (values) => {},
  });

  const familyMedicalHistory = useFormik({
    enableReinitialize: true,
    initialValues: {
      family_diabetes: consentData.family_diabetes || "",
      family_cancer: consentData.family_cancer || "",
      family_immune_problem: consentData.family_immune_problem || "",
      family_mental_problem: consentData.family_mental_problem || "",
      family_other_problem: consentData.family_other_problem || "",

      blood_disease: consentData.blood_disease || "",
      blood_disease_comment: consentData.blood_disease_comment || "",
      allergy_to_food: consentData.allergy_to_food || "",
      allergy_to_food_comment: consentData.allergy_to_food_comment || "",

      declare_valid_info: consentData.declare_valid_info || "",
      agree_health_services: consentData.agree_health_services || "",

      declare_parent_name: consentData.declare_parent_name || "",
      declare_Relationship: consentData.declare_relationship || "",
      // declare_Parent_Signature: "",
      declare_Date: moment(consentData.declare_date).format("YYYY-MM-DD") || "",
    },
    onSubmit: (values) => {},
  });

  const handlePrint = useReactToPrint({
    content: () => consentRef.current,
  });

  return (
    <Box>
      <Box ref={consentRef}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "#fff",
            minHeight: "128px",
            boxShadow: "0px 5px 5px #C4C4C417",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              pt: 3,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <img
                src={Gov_Dubai}
                alt=""
                style={
                  ismobilescreen
                    ? { height: "46px", width: "104px" }
                    : { height: "76px", width: "172px" }
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                mt: { xs: 3, md: 0 },
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "14px", md: "20px" }, fontWeight: 600 }}
              >
                PUBLIC HEALTH PROTECTION DEPARTMENT SCHOOL HEALTH SECTION
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "18px" },
                  color: "#4B4B5B",
                  textTransform: "uppercase",
                  mt: { xs: "8px", md: "0px" },
                }}
              >
                Student Medical Form & General Consent
              </Typography>
            </Box>

            <img
              src={DubaiHealth}
              alt=""
              style={
                ismobilescreen
                  ? { height: "46px", width: "104px" }
                  : { height: "76px", width: "172px" }
              }
            />
          </Box>
        </AppBar>
        <Box padding={3}>
          {/* //--------------------------- */}
          <Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: { xs: 0, md: "50px" },
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Dear Parent / Guardian of the Student:
                </Typography>
                <Typography>
                  Please fill the following form accurately to ensure
                  maintaining and monitoring your child's health during the
                  school year.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: "auto",
                  gap: 0.5,
                }}
              >
                <Avatar
                  src={studentData.values.profile_pic || ""}
                  sx={{
                    height: "96px",
                    width: "96px",
                    borderRadius: "6px",
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                School Information
              </Typography>
              <Box
                sx={{ ml: 2, flexGrow: 1, borderTop: ".8px solid #B4B4B7" }}
              ></Box>
            </Box>

            <Grid container spacing={1} mt={1}>
              <Grid item xs={4} md={3.5} lg={2.4}>
                <Textfield
                  label={"School Name"}
                  value={studentData.values.school_name || ""}
                  isDisable={true}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Textfield
                  label={"Grade"}
                  value={studentData.values.grade || ""}
                  isDisable={true}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Textfield
                  label={"Section"}
                  isDisable={true}
                  value={studentData.values.section || ""}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Student Information
              </Typography>
              <Box
                sx={{ ml: 2, flexGrow: 1, borderTop: ".8px solid #B4B4B7" }}
              ></Box>
            </Box>

            <Grid container spacing={1} mt={1}>
              <Grid item xs={5} lg={2.4}>
                <Textfield
                  label={"Student Full Name"}
                  isDisable={true}
                  value={studentData.values.student_name || ""}
                />
              </Grid>
              <Grid item xs={2} lg={2.4}>
                <Textfield
                  label={"Gender"}
                  isDisable={true}
                  value={studentData.values.gender || ""}
                />
              </Grid>
              <Grid item xs={2} lg={2.4}>
                <Textfield
                  label={"DOB"}
                  isDisable={true}
                  value={studentData.values.dob || ""}
                />
              </Grid>
              <Grid item xs={3} lg={2.4}>
                <Textfield
                  label={"Nationality"}
                  isDisable={true}
                  value={studentData.values.nationality || ""}
                />
              </Grid>
              <Grid item xs={5} lg={2.4}>
                <Textfield
                  label={"Parent / Legal Guardian Name"}
                  isDisable={true}
                  value={studentData.values.parent_name || ""}
                />
              </Grid>
              <Grid item xs={4} lg={2.4}>
                <Textfield
                  label={"Relationship"}
                  isDisable={true}
                  value={studentData.values.relation}
                />
              </Grid>{" "}
              <Grid item xs={4} lg={2.4}>
                <Textfield
                  label={"Mobile Number (1)"}
                  isDisable={true}
                  value={studentData.values.phone1 || ""}
                />
              </Grid>
              <Grid item xs={4} lg={2.4}>
                <Textfield
                  label={"Mobile Number (2)"}
                  isDisable={true}
                  value={studentData.values.phone2}
                />
              </Grid>
            </Grid>

            <Typography
              mt={2}
              sx={{
                fontSize: { md: "12px", lg: "13px", xl: "14px" },
                color: "#71717A",
              }}
            >
              In case of Emergency and we are unable reach the parent/guardian,
              the following person can be contacted
            </Typography>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={4} lg={2.4}>
                <Textfield
                  label={"Name"}
                  isDisable={true}
                  value={studentData.values.parent2_name}
                />
              </Grid>
              <Grid item xs={4} lg={2.4}>
                <Textfield
                  label={"Relationship"}
                  isDisable={true}
                  value={studentData.values.parent2_relation}
                />
              </Grid>
              <Grid item xs={3} lg={2.4}>
                <Textfield
                  label={"Mobile Number "}
                  isDisable={true}
                  value={studentData.values.parent2_phone}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Required Attachments
              </Typography>
              <Box
                sx={{ ml: 2, flexGrow: 1, borderTop: ".8px solid #B4B4B7" }}
              ></Box>
            </Box>

            <Grid container spacing={1} mt={1}>
              <Grid item xs={2.4}>
                <Box>
                  <Typography sx={{ fontSize: "13px" }}>
                    Student Emirates ID copy
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.haveEmiratesId}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.haveEmiratesId}
                        />
                      }
                      label="No"
                    />
                  </Box>
                  <Textfield
                    value={studentData.values.emiratesId}
                    isDisable={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.4}>
                <Box>
                  <Typography sx={{ fontSize: "13px" }}>
                    Health Card Copy (if any)
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.haveHealthCard}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.haveHealthCard}
                        />
                      }
                      label="No"
                    />
                  </Box>
                  <Textfield
                    value={studentData.values.healthcardNumber}
                    isDisable={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <Typography sx={{ fontSize: "13px" }}>
                    Student Passport copy
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.havePassportCopy}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.havePassportCopy}
                        />
                      }
                      label="No"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Typography sx={{ fontSize: "13px" }}>
                    Original Vaccination Card or Updated Copy
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.havevaccineCard}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.havevaccineCard}
                        />
                      }
                      label="No"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <Typography sx={{ fontSize: "13px" }}>
                    Health Insurance Card Copy (if any)
                  </Typography>
                  <Box display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={studentData.values.haveHealthInsurance}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!studentData.values.haveHealthInsurance}
                        />
                      }
                      label="No"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* //--------------------------- */}

          <Box mt={3}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Student Medical History
              </Typography>
              <Divider sx={{ ml: 2, flexGrow: 1 }} />
            </Box>

            <Grid container spacing={2} mt={1}>
              {diseaseList.map((val, index) => {
                return (
                  <Grid item xs={2.4} key={index}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        }}
                      >
                        {val.title}
                      </Typography>
                      <Box display={"flex"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={studentMedicalHistory.values[val.value]}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={!studentMedicalHistory.values[val.value]}
                            />
                          }
                          label="No"
                        />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>

            <Divider sx={{ mt: 2 }} />

            <Grid container spacing={2} mt={1} className="second-page">
              {enquires.map((val, index) => {
                return (
                  <Grid item xs={2.4} key={index}>
                    <Box height={{ xs: "auto", md: "150px", xl: "110px" }}>
                      <Typography
                        sx={{
                          fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        }}
                      >
                        {val.title}
                      </Typography>
                      <Box display={"flex"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={studentMedicalHistory.values[val.value]}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={!studentMedicalHistory.values[val.value]}
                            />
                          }
                          label="No"
                        />
                      </Box>
                    </Box>
                    <TextField
                      id={`${index}-enquiry`}
                      sx={{
                        backgroundColor: "#fff",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#DFDFDF",
                          },
                          "&:hover fieldset": {
                            borderColor: "#DFDFDF",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#DFDFDF",
                          },
                          "& input::placeholder": {
                            color: "#71717A",
                          },
                        },
                      }}
                      value={studentMedicalHistory.values[val.comment]}
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          {/* //--------------------------- */}

          <Box mt={3}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Family Medical History
              </Typography>
              <Divider sx={{ ml: 2, flexGrow: 1 }} />
            </Box>

            <Grid container spacing={2} mt={1}>
              {DiseaseList.map((val, index) => {
                return (
                  <Grid item xs={2.4} key={index}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        }}
                      >
                        {val.title}
                      </Typography>
                      <Box display={"flex"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={familyMedicalHistory.values[val.value]}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={!familyMedicalHistory.values[val.value]}
                            />
                          }
                          label="No"
                        />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Divider sx={{ mt: 3 }} />

            <Grid container spacing={2} mt={1}>
              {sufferings.map((val, index) => {
                return (
                  <Grid item xs={6} lg={2.4} key={index}>
                    <Box height={{ xs: "auto", md: "130px", xl: "110px" }}>
                      <Typography
                        sx={{
                          fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        }}
                      >
                        {val.title}
                      </Typography>
                      <Box display={"flex"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={familyMedicalHistory.values[val.value]}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={!familyMedicalHistory.values[val.value]}
                            />
                          }
                          label="No"
                        />
                      </Box>
                    </Box>
                    <TextField
                      id={`${index}-enquiry`}
                      sx={{
                        backgroundColor: "#fff",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#DFDFDF",
                          },
                          "&:hover fieldset": {
                            borderColor: "#DFDFDF",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#DFDFDF",
                          },
                          "& input::placeholder": {
                            color: "#71717A",
                          },
                        },
                      }}
                      value={familyMedicalHistory.values[val.comment]}
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Divider sx={{ mt: 3 }} />

            <Box mt={2}>
              <Typography>
                I agree for my child to have curative and/or preventive services
                that may include first aid, screening for height, weight, vision
                acuity, hearing test, dental checkup, Comprehensive Medical
                Examination, referral to emergency room when necessary,
                administer emergency medications when needed, and applying the
                Healthcare Management plan which is planned for based on the
                instructions of the treating doctor and parents.
              </Typography>
            </Box>
            <Divider sx={{ mt: 2 }} />

            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Parent/ Guardian approval and verification for the above
                mentioned information
              </Typography>

              <Grid container spacing={2} mt={0.5}>
                <Grid item xs={3}>
                  <Box display={"flex"} alignItems={"start"}>
                    <Checkbox
                      size="small"
                      checked={familyMedicalHistory.values.declare_valid_info}
                    />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        transform: "translate(0px,10px)",
                      }}
                    >
                      I certify that the above provided information are valid.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box display={"flex"} alignItems={"start"}>
                    <Checkbox
                      size="small"
                      checked={
                        familyMedicalHistory.values.agree_health_services
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        transform: "translate(0px,10px)",
                      }}
                    >
                      I agree for my child to be provided with the above
                      mentioned health services according to the need.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box display={"flex"} alignItems={"start"}>
                    <Checkbox
                      size="small"
                      checked={
                        !familyMedicalHistory.values.agree_health_services
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        transform: "translate(0px,10px)",
                      }}
                    >
                      I disagree for my child to be provided with the above
                      mentioned health services (in case of refusal, the above
                      services will not to be offered except in emergency
                      situations which require immediate intervention)
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={2}>
                {parentDetailList.map((val, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <Textfield
                        label={val.title}
                        value={familyMedicalHistory.values[val.value] || ""}
                        isDisable={true}
                      />
                    </Grid>
                  );
                })}
                <Grid item xs={2.4}>
                  <Textfield
                    label={"Date"}
                    value={familyMedicalHistory.values.declare_Date}
                    isDisable={true}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              className="second-page"
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
              mt={3}
            >
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                Notes
              </Typography>
              <Divider sx={{ ml: 2, flexGrow: 1 }} />
            </Box>

            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 500,
                }}
              >
                <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                Please attach medical reports about the students health problem,
                if any
              </Typography>

              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 500,
                }}
              >
                <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
                It is the responsibility of the Student's Parent/ Guardian to
                inform the school clinic of any changes in the student's health
                status and submit medical reports accordingly to update the
                Student's Medical Record at School.
              </Typography>
            </Box>

            <Box mt={6}>
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  fontWeight: 600,
                }}
              >
                **Please contact the School Doctor/Nurse if there are any
                queries**
              </Typography>
            </Box>
          </Box>

          {/* //--------------------------- */}
        </Box>
      </Box>
      <Box padding={2} display={"flex"} justifyContent={"center"}>
        <Button variant="contained" onClick={handlePrint}>
          Print
        </Button>
      </Box>
    </Box>
  );
};

export default GeneralConsentReport;
