import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

const Textfield = ({
  label,
  value,
  onChange,
  placeholder,
  style,
  type,
  endAdornment,
  onEndClick,
  isRequired,
  isDisable,
  helperText,
  error,
  defaultValue,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <div style={{ display: "flex", gap: "4px" }}>
        <span style={{ fontSize: "13px", fontWeight: 500 }}>{label}</span>
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <TextField
        size="small"
        autoComplete="off"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#DFDFDF",
            },
            "&:hover fieldset": {
              borderColor: "#DFDFDF",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#DFDFDF",
            },
            "& input::placeholder": {
              color: "#878A99",
            },
            "& input  ": {
              color: "#000",
            },
          },
          backgroundColor: "#fff",
          ...style,
        }}
        InputProps={{
          endAdornment: endAdornment && (
            <InputAdornment position="end">
              <IconButton onClick={onEndClick}>{endAdornment}</IconButton>
            </InputAdornment>
          ),
        }}
        disabled={isDisable}
        // helperText={helperText}
        error={error}
        defaultValue={defaultValue}
      />
      <Typography sx={{ fontSize: "10px", color: "red", ml: "auto" }}>
        {helperText}
      </Typography>
    </div>
  );
};

export default Textfield;
