import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import IconLogo from "../../Components/IconLogo";
import Scan from "../../Asset/scan 1.png";
import menuSearch from "../../Asset/menu-search-line.png";
import apiService from "../../service/apiService";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import { PiVirus, PiSpiralBold, PiBandaidsLight } from "react-icons/pi";
import { useParams } from "react-router-dom";
import SimpleDropdown from "../../Components/SimpleDropdown";
import APPCONSTANTS from "../../config/constant";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";
import Textfield from "../../Components/Textfield";
import Datefeild from "../../Components/Datefeild";
import { DatePicker } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

const IncidentDrawer = ({ open, handledrawerClose, HandleIncidentdata }) => {
  const isNonMobileScreen = useMediaQuery("(min-width:900px)");
  const url_params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state) => state.user);
  const classInRoman = APPCONSTANTS.classInRoman;

  const [page, setPage] = useState(1);
  const [input, setinput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selecteddStudent, setSelectedStudent] = useState("");
  const [onexamlength, setOnexamlength] = useState(0);
  const [generalCondition, setGeneralCondition] = useState(0);
  const [localExamin, setLocalExamin] = useState(0);
  const [actionTaken, setActionTaken] = useState(0);
  const [remarkslength, setRemarkslength] = useState(0);

  const formValidation = Yup.object().shape({
    reported_by: Yup.string().required("This field must not be blank."),
    on_examination: Yup.string()
      .min(1)
      .max(199, "Maximum 200 characters allowed")
      .required("This field must not be blank."),
    general_condition: Yup.string()
      .required()
      .min(1)
      .max(199, "Maximum 200 characters allowed")
      .required("This field must not be blank."),
    local_examination: Yup.string()
      .required()
      .min(1)
      .max(199, "Maximum 200 characters allowed")
      .required("This field must not be blank."),
    action_taken: Yup.string()
      .required()
      .min(1)
      .max(199, "Maximum 200 characters allowed")
      .required("This field must not be blank."),
    remarks: Yup.string()
      .required()
      .min(1)
      .max(199, "Maximum 200 characters allowed")
      .required("This field must not be blank."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: formValidation,
    initialValues: {
      for_action: false,
      for_information: false,
      admission_no: selecteddStudent ? selecteddStudent.admission_no : "",
      student_name: selecteddStudent ? selecteddStudent.student_name : "",
      class: selecteddStudent ? selecteddStudent.class : "",
      batch: selecteddStudent ? selecteddStudent.batch : "",
      reported_by: "",
      on_examination: "",
      general_condition: "",
      local_examination: "",
      action_taken: "",
      informed_parents: "",
      remarks: "",
    },
    onSubmit: (val) => {
      handleIncidentSave(val);
    },
    validateOnChange: false,
  });

  const informedparentsList = [
    { id: 1, name: "", value: "" },
    { id: 2, name: "Yes", value: "Yes" },
    { id: 3, name: "No", value: "No" },
  ];

  const handleSearch = (value) => {
    setinput(value);
    fetchStudentData(value);
  };

  const fetchStudentData = async (value) => {
    Commonservices.loaderON(dispatch);
    try {
      if (value.length > 3) {
        const req = {
          searchkey: value,
          inst_id: userData.institution_id,
        };

        let apiResult = await apiService.post(
          "bm-school/Docme-UI/registration/get_student_search_data",
          req
        );

        setSearchData(apiResult);

        if (value == "") {
          setSearchData([]);
        }
        if (apiResult === "No data found" || apiResult === "no data found") {
          setSearchData([]);
        }
      } else {
        setSearchData("");
      }
    } catch (error) {
      setSearchData([]);
      console.log(error);
    }
    Commonservices.loaderOFF(dispatch);
  };

  const handleSelectStudent = async (value) => {
    let parts = value.batch.split("/");

    let romanClass = parts[0];
    let partBatch = parts[1];
    let partSection = parts[3];

    // if (romanClass.length > 3) {
    //   romanClass = romanClass.slice(0, 3);
    // }

    let partClass = await classInRoman.find((val) => val.roman == romanClass);
    // console.log(partClass);

    setSelectedStudent({
      student_name: value.student_name,
      admission_no: value.Admn_No,
      class: partClass.number,
      batch: partBatch,
      profile_image: value.profile_image,
      father_name: value.father_name,
      father_phone: value.father_phone,
    });
    setPage(2);
  };

  const handleClose = () => {
    handledrawerClose();
    setSearchData("");
    setSelectedStudent("");
    setPage(1);
    setinput("");
  };

  const handleIncidentSave = async (val) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {};
      req = val;
      req.institution_id = userData.institution_id;
      req.academic_year = userData.academic_year;
      req.date = Date.now();
      let apiResult = await mysqlservice.post("save_incident", req);
      HandleIncidentdata();
      handleClose();
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
                borderRadius: 0,
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
                borderRadius: 0,
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Add Incident
            </Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>
        {/* content */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {page == 1 ? (
            <Box height={"100%"}>
              <Box p={2}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Search Student Name or Student Admission Number"
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: 20, color: "#8D9DA4" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconLogo image={Scan} size="24" bottom="-10" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    color: "#8D9DA4",
                    fontSize: "13px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        fontSize: "13px",
                        color: "#8D9DA4",
                      },
                      "& input": {
                        color: "black",
                      },
                    },
                    "& input::placeholder": {
                      color: "#000",
                      fontSize: "13px",
                    },
                  }}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
              </Box>
              {searchData && searchData.length ? (
                <Box px={2}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {searchData && searchData.length
                      ? searchData.map((val, index) => {
                          return (
                            <ListItem
                              sx={{
                                display: { xs: "block", md: "flex" },
                                alignItems: "start",
                                justifyContent: "space-between",
                              }}
                              key={index}
                              onClick={() => {
                                handleSelectStudent(val);
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Avatar
                                  src=""
                                  sx={{
                                    height: "34px",
                                    width: "34px",
                                    display: { xs: "none", md: "flex" },
                                  }}
                                />
                                <Box>
                                  <Typography
                                    sx={{ fontSize: "12px", fontWeight: 600 }}
                                  >
                                    {val.student_name}
                                  </Typography>
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {val.Class_code}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography sx={{ fontSize: "12px" }}>
                                Admn_no : {val.Admn_No}
                              </Typography>
                            </ListItem>
                          );
                        })
                      : ""}
                  </List>
                </Box>
              ) : input && searchData.length == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: isNonMobileScreen ? 4 : 2,
                  }}
                >
                  <Typography sx={{ fontSize: "30px", fontWeight: 700 }}>
                    No Data Found...
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: isNonMobileScreen ? 4 : 2,
                  }}
                >
                  <IconLogo image={menuSearch} />
                  <Typography sx={{ fontSize: "30px", fontWeight: 700 }}>
                    Search
                  </Typography>
                  <Typography fontSize="16px">
                    Enter student ID to view student details
                  </Typography>
                </Box>
              )}
            </Box>
          ) : page === 2 ? (
            <Box p={2}>
              <Grid container>
                <Grid item xs={12} sm={7} display={"flex"} gap={1}>
                  <Avatar
                    src={selecteddStudent.profile_image}
                    sx={{
                      height: "34px",
                      width: "34px",
                    }}
                  />
                  <Box>
                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                      {selecteddStudent.student_name}
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      Grade {selecteddStudent.class} - {"  "}
                      {selecteddStudent.batch}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 1 }} />

              <Box mt={2} display={"flex"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={formik.values.for_action}
                      onChange={() => {
                        formik.setFieldValue(
                          "for_action",
                          !formik.values.for_action
                        );
                      }}
                    />
                  }
                  label="For Action"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={formik.values.for_information}
                      onChange={() => {
                        formik.setFieldValue(
                          "for_information",
                          !formik.values.for_information
                        );
                      }}
                    />
                  }
                  label="For Information"
                />
              </Box>
              <Box mt={2} display={"flex"} flexDirection={"column"}>
                <Textfield
                  label={"Reported by"}
                  placeholder={"Enter Reported by"}
                  value={formik.values.reported_by}
                  onChange={(e) => {
                    formik.setFieldValue("reported_by", e.target.value);
                  }}
                  error={formik.errors.reported_by}
                  helperText={formik.errors.reported_by}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"} mt={1}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  On Examination
                </Typography>
                <textarea
                  cols="30"
                  rows="5"
                  style={{
                    outline: 0,
                    border: formik.errors.on_examination
                      ? "1px solid red"
                      : "1px solid #cbc9cc",
                    padding: 10,
                    borderRadius: "4px",
                    color: "#000",
                  }}
                  value={formik.values.on_examination}
                  onChange={(e) => {
                    formik.setFieldValue("on_examination", e.target.value);
                    let val = e.target.value;
                    setOnexamlength(val.length);
                  }}
                  placeholder="Enter On Examination"
                  maxLength="200"
                ></textarea>
                {formik.errors.on_examination ? (
                  <Typography
                    sx={{ ml: "auto", color: "red", fontSize: "10px" }}
                  >
                    {formik.errors.on_examination}
                  </Typography>
                ) : (
                  <Typography sx={{ ml: "auto" }}>
                    {onexamlength}/200
                  </Typography>
                )}
              </Box>
              <Box display={"flex"} flexDirection={"column"} mt={1}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  General Condition
                </Typography>
                <textarea
                  cols="30"
                  rows="5"
                  style={{
                    outline: 0,
                    padding: 10,
                    border: formik.errors.general_condition
                      ? "1px solid red"
                      : "1px solid #cbc9cc",
                    borderRadius: "4px",
                    color: "#000",
                  }}
                  value={formik.values.general_condition}
                  onChange={(e) => {
                    formik.setFieldValue("general_condition", e.target.value);
                    let val = e.target.value;
                    setGeneralCondition(val.length);
                  }}
                  placeholder="Enter General Condition"
                  maxLength="200"
                ></textarea>
                {formik.errors.general_condition ? (
                  <Typography
                    sx={{ ml: "auto", color: "red", fontSize: "10px" }}
                  >
                    {formik.errors.general_condition}
                  </Typography>
                ) : (
                  <Typography sx={{ ml: "auto" }}>
                    {generalCondition}/200
                  </Typography>
                )}
              </Box>
              <Box display={"flex"} flexDirection={"column"} mt={1}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  Local examination
                </Typography>
                <textarea
                  cols="30"
                  rows="5"
                  style={{
                    outline: 0,
                    padding: 10,
                    border: formik.errors.local_examination
                      ? "1px solid red"
                      : "1px solid #cbc9cc",
                    borderRadius: "4px",
                    color: "#000",
                  }}
                  value={formik.values.local_examination}
                  onChange={(e) => {
                    formik.setFieldValue("local_examination", e.target.value);
                    let val = e.target.value;
                    setLocalExamin(val.length);
                  }}
                  placeholder="Enter Local examination"
                  maxLength="200"
                ></textarea>
                {formik.errors.local_examination ? (
                  <Typography
                    sx={{ ml: "auto", color: "red", fontSize: "10px" }}
                  >
                    {formik.errors.local_examination}
                  </Typography>
                ) : (
                  <Typography sx={{ ml: "auto" }}>{localExamin}/200</Typography>
                )}
              </Box>

              <Box display={"flex"} flexDirection={"column"} mt={1}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  Action Taken
                </Typography>
                <textarea
                  cols="30"
                  rows="5"
                  style={{
                    outline: 0,
                    padding: 10,
                    border: formik.errors.action_taken
                      ? "1px solid red"
                      : "1px solid #cbc9cc",
                    borderRadius: "4px",
                    color: "#000",
                  }}
                  value={formik.values.action_taken}
                  onChange={(e) => {
                    formik.setFieldValue("action_taken", e.target.value);
                    let val = e.target.value;
                    setActionTaken(val.length);
                  }}
                  placeholder="Enter Action Taken"
                  maxLength="200"
                ></textarea>
                {formik.errors.action_taken ? (
                  <Typography
                    sx={{ ml: "auto", color: "red", fontSize: "10px" }}
                  >
                    {formik.errors.action_taken}
                  </Typography>
                ) : (
                  <Typography sx={{ ml: "auto" }}>{actionTaken}/200</Typography>
                )}
              </Box>

              <FormControl size="small" sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  Informed Parents
                </Typography>
                <Select
                  placeholder="Select status"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.informed_parents}
                  onChange={(e) => {
                    formik.setFieldValue("informed_parents", e.target.value);
                  }}
                  sx={{ backgroundColor: "#fff", color: "#000" }}
                  fullWidth
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <p style={{ color: "gray" }}>Select Informed Parents</p>
                      );
                    }
                    return selected;
                  }}
                >
                  {informedparentsList && informedparentsList.length
                    ? informedparentsList.map((data, index) => {
                        return (
                          <MenuItem value={data.value} key={index}>
                            {data.name}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>

              <Box display={"flex"} flexDirection={"column"} mt={1}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  Remarks
                </Typography>
                <textarea
                  cols="30"
                  rows="5"
                  style={{
                    outline: 0,
                    padding: 10,
                    border: formik.errors.remarks
                      ? "1px solid red"
                      : "1px solid #cbc9cc",
                    borderRadius: "4px",
                    color: "#000",
                  }}
                  value={formik.values.remarks}
                  onChange={(e) => {
                    formik.setFieldValue("remarks", e.target.value);
                    let val = e.target.value;
                    setRemarkslength(val.length);
                  }}
                  placeholder="Enter Remarks"
                  maxLength="200"
                ></textarea>
                {formik.errors.remarks ? (
                  <Typography
                    sx={{ ml: "auto", color: "red", fontSize: "10px" }}
                  >
                    {formik.errors.remarks}
                  </Typography>
                ) : (
                  <Typography sx={{ ml: "auto" }}>
                    {remarkslength}/200
                  </Typography>
                )}
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {/* footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default IncidentDrawer;
