import { Box, Typography, useTheme } from "@mui/material";
import { DatePicker } from "antd";
import React from "react";

const Datefeild = ({
  label,
  value,
  onChange,
  placeholder,
  style,
  status,
  helperText,
  isrequired,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
      className={theme.palette.mode === "dark" ? "dark-date" : ""}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <span style={{ fontSize: "13px", fontWeight: 500 }}>{label}</span>
        {isrequired ? <span style={{ color: "red" }}>*</span> : ""}
      </Box>

      <DatePicker
        value={value}
        placeholder={placeholder}
        style={{
          height: "36px",
          padding: "4px",
          border: "1px solid #DFDFDF",
          borderRadius: "5px",
          ...style,
        }}
        onChange={onChange}
        status={status}
        className="custom-datepicker"
      />
      <Typography sx={{ fontSize: "10px", color: "red", ml: "auto" }}>
        {helperText}
      </Typography>
    </div>
  );
};

export default Datefeild;
