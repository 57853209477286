import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Textfield from "../../../Components/Textfield";

const GeneralFirstpage = ({ studentData }) => {
  const fileInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl);
      studentData.setFieldValue("profile_pic", imageUrl);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: { xs: 0, md: "50px" },
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Dear Parent / Guardian of the Student:
          </Typography>
          <Typography>
            Please fill the following form accurately to ensure maintaining and
            monitoring your child's health during the school year.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            gap: 0.5,
          }}
        >
          <Avatar
            src={studentData.values.profile_pic || ""}
            sx={{
              height: "96px",
              width: "96px",
              borderRadius: "6px",
            }}
          />
          {/* <Button variant="contained" onClick={handleClick}>
            Upload
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Button> */}
        </Box>
      </Box>

      <Box sx={{ mt: 2, display: "flex", alignItems: "center", width: "100%" }}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          School Information
        </Typography>
        <Box sx={{ ml: 2, flexGrow: 1, borderTop: ".8px solid #B4B4B7" }}></Box>
      </Box>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"School Name"}
            isRequired={true}
            value={studentData.values.school_name || ""}
            onChange={(e) => {
              studentData.setFieldValue("school_name", e.target.value);
            }}
            placeholder={"Enter School Name"}
            // helperText={studentData.errors.school_name}
            error={studentData.errors.school_name}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Grade"}
            isRequired={true}
            value={studentData.values.grade || ""}
            onChange={(e) => {
              studentData.setFieldValue("grade", e.target.value);
            }}
            placeholder={"Enter Grade"}
            error={studentData.errors.grade}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Section"}
            isRequired={true}
            value={studentData.values.section || ""}
            onChange={(e) => {
              studentData.setFieldValue("section", e.target.value);
            }}
            placeholder={"Enter Section"}
            error={studentData.errors.section}
            isDisable={true}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: "flex", alignItems: "center", width: "100%" }}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Student Information
        </Typography>
        <Box sx={{ ml: 2, flexGrow: 1, borderTop: ".8px solid #B4B4B7" }}></Box>
      </Box>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Student Full Name"}
            isRequired={true}
            value={studentData.values.student_name || ""}
            onChange={(e) => {
              studentData.setFieldValue("student_name", e.target.value);
            }}
            placeholder={"Enter Student Full Name"}
            error={studentData.errors.student_name}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Gender"}
            isRequired={true}
            value={studentData.values.gender || ""}
            onChange={(e) => {
              studentData.setFieldValue("gender", e.target.value);
            }}
            placeholder={"Enter Gender"}
            error={studentData.errors.gender}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"DOB"}
            isRequired={true}
            value={studentData.values.dob || ""}
            onChange={(e) => {
              studentData.setFieldValue("dob", e.target.value);
            }}
            placeholder={"Enter DOB"}
            error={studentData.errors.dob}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Nationality"}
            isRequired={true}
            value={studentData.values.nationality || ""}
            onChange={(e) => {
              studentData.setFieldValue("nationality", e.target.value);
            }}
            placeholder={"Enter Nationality"}
            error={studentData.errors.nationality}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Parent / Legal Guardian Name"}
            isRequired={true}
            value={studentData.values.parent_name || ""}
            onChange={(e) => {
              studentData.setFieldValue("parent_name", e.target.value);
            }}
            placeholder={"Enter Parent / Legal Guardian Name"}
            error={studentData.errors.parent_name}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Relationship"}
            isRequired={true}
            value={studentData.values.relation}
            onChange={(e) => {
              studentData.setFieldValue("relation", e.target.value);
            }}
            placeholder={"Enter Relationship"}
            error={studentData.errors.relation}
            helperText={studentData.errors.relation}
          />
        </Grid>{" "}
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Mobile Number (1)"}
            isRequired={true}
            value={studentData.values.phone1 || ""}
            onChange={(e) => {
              studentData.setFieldValue("phone1", e.target.value);
            }}
            placeholder={"Enter Mobile Number (1)"}
            error={studentData.errors.phone1}
            isDisable={true}
            type={"number"}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Mobile Number (2)"}
            isRequired={true}
            value={studentData.values.phone2}
            onChange={(e) => {
              studentData.setFieldValue("phone2", e.target.value);
            }}
            placeholder={"Enter Mobile Number (2)"}
            error={studentData.errors.phone2}
            helperText={studentData.errors.phone2}
            type={"number"}
          />
        </Grid>
      </Grid>
      <Typography
        mt={2}
        sx={{
          fontSize: { md: "12px", lg: "13px", xl: "14px" },
          color: "#71717A",
        }}
      >
        In case of Emergency and we are unable reach the parent/guardian, the
        following person can be contacted
      </Typography>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Name"}
            isRequired={true}
            value={studentData.values.parent2_name}
            onChange={(e) => {
              studentData.setFieldValue("parent2_name", e.target.value);
            }}
            placeholder={"Enter Name"}
            error={studentData.errors.parent2_name}
            helperText={studentData.errors.parent2_name}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Relationship"}
            isRequired={true}
            value={studentData.values.parent2_relation}
            onChange={(e) => {
              studentData.setFieldValue("parent2_relation", e.target.value);
            }}
            placeholder={"Enter Relationship"}
            error={studentData.errors.parent2_relation}
            helperText={studentData.errors.parent2_relation}
          />
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Textfield
            label={"Mobile Number "}
            isRequired={true}
            value={studentData.values.parent2_phone}
            onChange={(e) => {
              studentData.setFieldValue("parent2_phone", e.target.value);
            }}
            placeholder={"Enter Mobile Number"}
            error={studentData.errors.parent2_phone}
            helperText={studentData.errors.parent2_phone}
            type={"number"}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: "flex", alignItems: "center", width: "100%" }}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Required Attachments
        </Typography>
        <Box sx={{ ml: 2, flexGrow: 1, borderTop: ".8px solid #B4B4B7" }}></Box>
      </Box>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Box>
            <Typography sx={{ fontSize: "13px" }}>
              Student Emirates ID copy
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.haveEmiratesId}
                    onChange={(value) => {
                      studentData.setFieldValue("haveEmiratesId", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.haveEmiratesId}
                    onChange={(value) => {
                      studentData.setFieldValue("haveEmiratesId", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
            <Textfield
              placeholder={"Enter Emirates ID Number"}
              value={studentData.values.emiratesId}
              onChange={(e) => {
                studentData.setFieldValue("emiratesId", e.target.value);
              }}
              isDisable={!studentData.values.haveEmiratesId ? true : false}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Box>
            <Typography sx={{ fontSize: "13px" }}>
              Health Card Copy (if any)
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.haveHealthCard}
                    onChange={(value) => {
                      studentData.setFieldValue("haveHealthCard", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.haveHealthCard}
                    onChange={(value) => {
                      studentData.setFieldValue("haveHealthCard", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
            <Textfield
              placeholder={"Enter Health Card Number"}
              value={studentData.values.healthcardNumber}
              onChange={(e) => {
                studentData.setFieldValue("healthcardNumber", e.target.value);
              }}
              isDisable={!studentData.values.haveHealthCard ? true : false}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5} lg={2}>
          <Box>
            <Typography sx={{ fontSize: "13px" }}>
              Student Passport copy
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.havePassportCopy}
                    onChange={(value) => {
                      studentData.setFieldValue("havePassportCopy", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.havePassportCopy}
                    onChange={(value) => {
                      studentData.setFieldValue("havePassportCopy", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5} lg={3}>
          <Box>
            <Typography sx={{ fontSize: "13px" }}>
              Original Vaccination Card or Updated Copy
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.havevaccineCard}
                    onChange={(value) => {
                      studentData.setFieldValue("havevaccineCard", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.havevaccineCard}
                    onChange={(value) => {
                      studentData.setFieldValue("havevaccineCard", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5} lg={2}>
          <Box>
            <Typography sx={{ fontSize: "13px" }}>
              Health Insurance Card Copy (if any)
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.haveHealthInsurance}
                    onChange={(value) => {
                      studentData.setFieldValue("haveHealthInsurance", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.haveHealthInsurance}
                    onChange={(value) => {
                      studentData.setFieldValue("haveHealthInsurance", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralFirstpage;
