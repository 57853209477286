import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PiVirus, PiSpiralBold, PiBandaidsLight } from "react-icons/pi";
import Commonservices from "../../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../../service/mySqlservice";
import { toast } from "react-toastify";
import Textfield from "../../../Components/Textfield";
import { useFormik } from "formik";
import SimpleDropdown from "../../../Components/SimpleDropdown";
import * as Yup from "yup";

const CheckupDrawer = ({ open, onClose, studentData, handleStudentData }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state) => state.user);

  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    setInputValue(
      (studentData && studentData.remarks && studentData.remarks.length) || 0
    );
  }, [open]);

  const checkupvalidation = Yup.object().shape({
    remarks: Yup.string()
      .min(1)
      .max(499, "Maximum 500 characters allowed")
      .required("This field must not be blank."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: checkupvalidation,
    initialValues: {
      type: studentData.checkup_type || "",
      status: studentData.checkup_status || "",
      remarks: studentData.remarks || "",
    },
    onSubmit: (val) => {
      handleChecupUpdate();
    },
  });

  const generalBuiltlist = [
    { id: 1, name: "Normal", value: "Normal" },
    { id: 2, name: "Thinness", value: "Thinness" },
    { id: 3, name: "Severe Thinness", value: "Severe Thinness" },
    { id: 4, name: "Over weight", value: "Over weight" },
    { id: 5, name: "obese", value: "obese" },
    { id: 6, name: "Morbid obesity", value: "Morbid obesity" },
  ];

  const eyeStatusList = [
    { id: 1, name: "Normal", value: "Normal" },
    { id: 2, name: "Refractive error", value: "Refractive error" },
  ];

  const earStatusList = [
    { id: 1, name: "Normal", value: "Normal" },
    { id: 2, name: "Rinnes -ve", value: "Rinnes -ve" },
  ];

  const oralHealthStatusList = [
    { id: 1, name: "Normal", value: "Normal" },
    { id: 2, name: "carries", value: "carries" },
    { id: 3, name: "cavity", value: "cavity" },
  ];

  const scoliosisStatusList = [
    { id: 1, name: "Normal", value: "Normal" },
    { id: 2, name: "Scoliosis +ve", value: "Scoliosis +ve" },
  ];

  const mentalHealthStatusList = [
    { id: 1, name: "Normal", value: "Normal" },
    { id: 2, name: "Depression", value: "Depression" },
    { id: 3, name: "Anxiety", value: "Anxiety" },
    { id: 4, name: "Eating disorder", value: "Eating disorder" },
  ];

  let menuItems;

  if (studentData.checkup_type === "General Built") {
    menuItems = generalBuiltlist;
  } else if (studentData.checkup_type === "Eyes") {
    menuItems = eyeStatusList;
  } else if (studentData.checkup_type === "Ears") {
    menuItems = earStatusList;
  } else if (studentData.checkup_type === "Oral Health") {
    menuItems = oralHealthStatusList;
  } else if (studentData.checkup_type === "Scoliosis") {
    menuItems = scoliosisStatusList;
  } else if (studentData.checkup_type === "Mental Health") {
    menuItems = mentalHealthStatusList;
  }

  const handleClose = () => {
    onClose();
    menuItems = "";
    setInputValue(0);
  };

  const handleChecupUpdate = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        id: studentData.checkup_id,
        checkup_status: formik.values.status,
        remarks: formik.values.remarks,
      };

      let apiresult = await mysqlservice.post("update_checkup", req);
      toast.success("Checkup status sent to Parent");
      formik.resetForm();
      handleClose();
      handleStudentData();
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Health Checkup Status{" "}
            </Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Box p={2}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={7}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <Avatar
                  src={studentData.profile_image}
                  sx={{
                    height: "34px",
                    width: "34px",
                  }}
                />
                <Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    {studentData.student_name}
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Grade {studentData.class} - {"  "}
                    {studentData.batch_detail}
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    {studentData.Admn_No}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                textAlign={{ xs: "left", sm: "right" }}
                mt={{ xs: 2, sm: 0 }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Parents Details
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#8D949C",
                      }}
                    >
                      Name &nbsp;-
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip title={studentData.father_name} placement="top">
                      <Typography
                        sx={{ fontSize: "12px", textTransform: "capitalize" }}
                      >
                        &nbsp;{}
                        {studentData.father_name
                          ? studentData.father_name.length > 15
                            ? `${studentData.father_name.substring(0, 15)}...`
                            : studentData.father_name
                          : "--"}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#8D949C",
                      }}
                    >
                      Mobile &nbsp;-
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: "12px", textTransform: "capitalize" }}
                    >
                      &nbsp;{studentData.father_phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1 }} />

            <Box
              mt={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <Box>
                <Typography>Type</Typography>
                <Box
                  sx={{
                    border: "1px solid gray",
                    p: 1,
                    backgroundColor: "#fff",
                    color: "gray",
                    borderRadius: "4px",
                  }}
                >
                  <Typography>{formik.values.type}</Typography>
                </Box>
              </Box>

              <FormControl size="small">
                <Typography>Status</Typography>
                <Select
                  placeholder="Select status"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.status}
                  onChange={(e) => {
                    formik.setFieldValue("status", e.target.value);
                  }}
                  sx={{ backgroundColor: "#fff", color: "#000" }}
                  fullWidth
                >
                  {menuItems && menuItems.length
                    ? menuItems.map((data, index) => {
                        return (
                          <MenuItem value={data.value} key={index}>
                            {data.name}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>

              <Box display={"flex"} flexDirection={"column"}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  Remarks
                </Typography>
                <textarea
                  cols="30"
                  rows="10"
                  style={{
                    outline: 0,
                    borderColor: "#DFDFDF",
                    padding: 10,
                    color: "#000",
                  }}
                  value={formik.values.remarks}
                  onChange={(e) => {
                    formik.setFieldValue("remarks", e.target.value);
                    let val = e.target.value;
                    setInputValue(val.length);
                  }}
                  placeholder="Enter Remarks"
                  maxLength="500"
                ></textarea>
                {formik.errors.remarks ? (
                  <Typography sx={{ ml: "auto", color: "red" }}>
                    {formik.errors.remarks}
                  </Typography>
                ) : (
                  <Typography sx={{ ml: "auto" }}>{inputValue}/500</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handleClose}>
              Close
            </Button>

            <Button
              variant="contained"
              sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
              onClick={formik.handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CheckupDrawer;
