import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import {
  BiMenuAltLeft,
  BiCategory,
  BiShoppingBag,
  BiFullscreen,
  BiSun,
} from "react-icons/bi";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import UserImg from "../../Asset/User.png";
import Flag from "../../Asset/Flag.png";
import IconLogo from "../../Components/IconLogo";
import { DarkMode } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setBatch,
  setClass,
  setLogout,
  setMode,
  setSideBar,
} from "../../state/index";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

const Navbar = ({ sideBarOpen, setSideBarOpen, notificationCount }) => {
  const [auth, setAuth] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dailogOpen, setDailogOpen] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const theme = useTheme();
  const navigate = useNavigate();
  // const dark = theme.palette.neutral.light;

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDailogOpen = () => {
    setDailogOpen(true);
  };

  const handleDailogClose = () => {
    setDailogOpen(false);
    handleClose();
  };

  const logoutClick = () => {
    dispatch(
      setClass({
        class: "",
      }),
      setBatch({
        batch: "",
      }),
      setSideBar({
        listItem: 1,
      })
    );
    dispatch(setLogout());
    navigate("/");
  };

  const handleNotification = () =>{
    navigate("/notifications")
  }

  return (
    <Box
    // sx={
    //   !sideBarOpen
    //     ? { width: "100%" }
    //     : { width: "calc(100% - 250px)", ml: "auto" }
    // }
    >
      <AppBar
        position="static"
        sx={
          theme.palette.mode === "dark"
            ? {
                backgroundColor: "#000",
                boxShadow: 0,
                borderBottom: "1px solid #DEDEDE",
              }
            : {
                backgroundColor: "#efefee",
                boxShadow: 0,
                borderBottom: "1px solid #DEDEDE",
              }
        }
      >
        <Toolbar>
          {sideBarOpen ? (
            <Tooltip title="Close Menu">
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: { xs: 0, md: 2 } }}
                onClick={() => {
                  setSideBarOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Open Menu">
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: { xs: 0, md: 2 } }}
                onClick={() => {
                  setSideBarOpen(true);
                }}
              >
                <BiMenuAltLeft />
              </IconButton>
            </Tooltip>
          )}
          {/* <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder="Search..."
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: 20, color: "#878A99" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
              color: "#878A99",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              "& input::placeholder": {
                color: "#878A99",
              },
            }}
          /> */}
          <Box ml={"auto"} sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {/* <IconLogo
                image={Flag}
                size="18"
                radius="0"
                right="12px"
                // top="4px"
              />
              <IconButton>
                <BiCategory fontSize={20} />
              </IconButton> */}
              {/* <Badge
                badgeContent={7}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#FECF73",
                    color: "#000",
                  },
                  cursor: "pointer",
                }}
              >
                <BiShoppingBag fontSize={20} />
              </Badge>
              <IconButton>
                <BiFullscreen fontSize={20} />
              </IconButton> */}
              {theme.palette.mode === "dark" ? (
                <Tooltip title="Switch to light mode">
                  <IconButton onClick={() => dispatch(setMode())}>
                    <DarkMode sx={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Switch to dark mode">
                  <IconButton onClick={() => dispatch(setMode())}>
                    <BiSun sx={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              )}

              <Badge
                badgeContent={notificationCount}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#FECF73",
                    color: "#000",
                  },
                  cursor: "pointer",
                }}
                onClick={handleNotification}
              >
                <NotificationsIcon color="action" />
              </Badge>
            </Box>

            <Box
              sx={{ backgroundColor: "white", p: 2, display: "flex", gap: 1 }}
              onClick={handleMenu}
            >
              <Avatar /*src={UserImg}*/ src="" sx={{ width: 34, height: 34 }} />
              <Box sx={{ color: "black" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {userData && userData.name
                    ? userData.name.length > 15
                      ? `${userData.name.substring(0, 15)}...`
                      : userData.name
                    : ""}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {userData && userData.role == 10
                    ? "Admin"
                    : userData && userData.role == 20
                    ? "Counsler"
                    : userData && userData.role == "30"
                    ? "Doctor"
                    : "Nurse"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Modal open={dailogOpen} onClose={handleDailogClose}>
            <Box sx={style}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 500, textAlign: "center" }}
              >
                Are you sure you want to log out?
              </Typography>
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                  onClick={handleDailogClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                  onClick={logoutClick}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </Modal>

          <Box sx={{ ml: "auto", display: { xs: "flex", md: "none" } }}>
            <IconButton onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem onClick={handleClose}>
              <AccountCircleIcon />
            </MenuItem> */}
            <Tooltip title="Logout">
              <MenuItem onClick={handleDailogOpen}>
                <LogoutIcon />
              </MenuItem>
            </Tooltip>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
