import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../DashBoard/Index";
import { Box } from "@mui/material";
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";
import VisitIndex from "../ClinicalVisit/Index";
import Index from "../Tracking/Index";
import ConsentIndex from "../Consent/ConsentIndex";
import VaccineIndex from "../Consent/VaccineIndex";
import LoginPage from "../Login/Index";
import { AuthProtected } from "./AuthProtected";
import PasswordUpdate from "../Login/PasswordUpdate";
import GeneralConsentForm from "../Consent/GeneralConsentForm";
import SettingsIndex from "../Settings/SettingsIndex";
import NotFound from "../../Components/NotFound";
import SectionWiseReport from "../Reports/SectionWiseReport";
import ClasswiseReport from "../Reports/ClasswiseReport";
import GeneralconsentReport from "../Reports/GeneralconsentReport";
import VaccinationReport from "../Reports/VaccinationReport";
import VaccineFormIndex from "../Consent/VaccineFormIndex";
import IncidentIndex from "../Incidents/Index";
import { useDispatch, useSelector } from "react-redux";
import CounselIndex from "../Counsel/CounselIndex";
import IncidentReport from "../Reports/IncidentReport";
import NotificationIndex from "../Clinic_notification/Send_Notification/NotificationIndex";
import GeneralConsentReport from "../Consent/ConsentReport/GeneralConsentReport";
import VaccinationConsentReport from "../Consent/ConsentReport/VaccinationConsentReport";
import HealthIndex from "../Clinic_notification/HealthCheckup/HealthIndex";
import Commonservices from "../../service/Commonservices";
import mysqlservice from "../../service/mySqlservice";
import AllnotificationIndex from "../Notifications/AllnotificationIndex";

const AppRoute = () => {
  const userData = useSelector((state) => state.user);
  const isAuth = localStorage.getItem("authUser");
  const dispatch = useDispatch();

  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    handleFetchNotification();

    const intervalTime = setInterval(() => {
      handleFetchNotification();
    }, 30000);

    return () => clearInterval(intervalTime);
  }, []);

  const handleFetchNotification = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        role: userData.role,
      };

      let apiResult = await mysqlservice.post("notifications", req);
      setNotificationData(apiResult.data);
      setNotificationCount(apiResult.count);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const privilege = userData && userData.privileges ? userData.privileges : [];

  const clinical_privilege =
    privilege && privilege.find((data) => data.module == "clinical_visit");

  const student_tracking =
    privilege && privilege.find((data) => data.module == "student_tracking");

  const authProtectedRoutes = [
    { path: "/home", component: <Dashboard /> },
    {
      path: "/details/:date/:cal",
      component: <VisitIndex privilege={clinical_privilege} />,
    },
    { path: "/track", component: <Index privilege={student_tracking} /> },
    { path: "/generalconsent", component: <ConsentIndex /> },
    { path: "/vaccineconsent", component: <VaccineIndex /> },
    { path: "/settings", component: <SettingsIndex /> },
    { path: "/section_report", component: <SectionWiseReport /> },
    { path: "/class_report", component: <ClasswiseReport /> },
    { path: "/general_report", component: <GeneralconsentReport /> },
    { path: "/vaccine_report", component: <VaccinationReport /> },
    { path: "/incident_report", component: <IncidentReport /> },
    { path: "/incident", component: <IncidentIndex /> },
    { path: "/counsel_track", component: <CounselIndex /> },
    { path: "/send_notification", component: <NotificationIndex /> },
    { path: "/health_check_up", component: <HealthIndex /> },
    {
      path: "/notifications",
      component: (
        <AllnotificationIndex
          notificationData={notificationData}
          handleFetchNotification={handleFetchNotification}
        />
      ),
    },
  ];

  // console.log(isAuth);

  return (
    <Routes>
      <Route path={`/reset-password/:id/:token`} element={<PasswordUpdate />} />
      <Route
        path={`/generalconsentform/:id/:token`}
        element={<GeneralConsentForm />}
      />
      <Route
        path={`/vaccineconsentform/:id/:token`}
        element={<VaccineFormIndex />}
      />

      <Route
        path={`/general_consent_report/:id`}
        element={<GeneralConsentReport />}
      />
      <Route
        path={`/vaccination_consent_report/:id`}
        element={<VaccinationConsentReport />}
      />
      <Route path={`*`} element={<NotFound />} />
      <Route
        path="/"
        element={
          isAuth ? (
            <AuthProtected>
              <Box display={"flex"} width={"100%"}>
                {sideBarOpen ? (
                  <Sidebar
                    sideBarOpen={sideBarOpen}
                    setSideBarOpen={setSideBarOpen}
                  />
                ) : (
                  ""
                )}
                <Box sx={{ height: "100vh", overflow: "auto", width: "100%" }}>
                  <Navbar
                    sideBarOpen={sideBarOpen}
                    setSideBarOpen={setSideBarOpen}
                    // toggleFullScreen={toggleFullScreen}
                    notificationCount={notificationCount}
                  />
                  <Box>
                    <Dashboard />
                  </Box>
                </Box>
              </Box>
            </AuthProtected>
          ) : (
            <Box p={1}>
              <LoginPage />
            </Box>
          )
        }
      />
      {authProtectedRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <AuthProtected>
              <Suspense>
                <Box display={"flex"} width={"100%"}>
                  {sideBarOpen ? (
                    <Sidebar
                      sideBarOpen={sideBarOpen}
                      setSideBarOpen={setSideBarOpen}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    sx={{ height: "100vh", overflow: "auto", width: "100%" }}
                  >
                    <Navbar
                      sideBarOpen={sideBarOpen}
                      setSideBarOpen={setSideBarOpen}
                      // toggleFullScreen={toggleFullScreen}
                      notificationCount={notificationCount}
                    />
                    <Box>{route.component}</Box>
                  </Box>
                </Box>
              </Suspense>
            </AuthProtected>
          }
          key={idx}
          exact
        />
      ))}
    </Routes>
  );
};

export default AppRoute;
