import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PiVirus, PiSpiralBold, PiBandaidsLight } from "react-icons/pi";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateDrawer = ({
  open,
  onClose,
  selecteddStudent,
  setSelectedStudent,
  updateId,
  setUpdateId,
  handleStudentData,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state) => state.user);
  const [visitButtonId, setVisitButtonId] = useState("By Teacher");
  const [reasonButtonId, setReasonButtonId] = useState("Disease");
  const [statusButtonId, setStatusButtonId] = useState(
    "Sent to Isolation Room"
  );
  const [isDiasbled, setisDisabled] = useState(false);
  const [error, setError] = useState({ remarks: "" });
  const [informButtonId, setInformButtonId] = useState(true);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [addStudentData, setAddStudentData] = useState({
    name: "",
    class: "",
    batch: "",
    image: "",
    date: "",
    visit_type: "",
    reason: "",
    situation: "",
    visit_status: "",
    informed_parent: "",
    remarks: "",
    contact_status: "",
  });

  useEffect(() => {
    setAddStudentData((prev) => ({
      ...prev,
      name: (updateId && updateId.student_name) || "",
      class: (updateId && updateId.class) || "",
      batch: (updateId && updateId.batch) || "",
      image: (updateId && updateId.profile_pic) || "",
      date: (updateId && updateId.visit_date) || "",
      visit_type: (updateId && updateId.visit_type) || "",
      reason: updateId && updateId.reason ? updateId.reason : "Disease" || "",
      situation:
        updateId && updateId.situation
          ? updateId.situation
          : "Infectious" || "",
      visit_status:
        updateId && updateId.visit_status
          ? updateId.visit_status
          : "Sent to Isolation Room" || "",
      informed_parent:
        updateId && updateId.informed_parent
          ? updateId.informed_parent
          : true || "",
      remarks: updateId && updateId.remarks ? updateId.remarks : "" || "",
      contact_status:
        updateId && updateId.contact_status
          ? updateId.contact_status
          : "" || "",
    }));
  }, [open]);

  const visitButtons = [
    {
      id: 1,
      title: "By Teacher",
      value: "By Teacher",
    },
    { id: 2, title: "Direct Visit", value: "Direct_visit" },
  ];

  const reasonButton = [
    {
      id: 1,
      title: "Disease",
      icon: (
        <PiVirus
          style={
            reasonButtonId === 1
              ? { height: "22px", width: "22px" }
              : { height: "22px", width: "22px", color: "#3BA5B9" }
          }
        />
      ),
      value: "Disease",
    },
    {
      id: 2,
      title: "Injury",
      icon: (
        <PiBandaidsLight
          style={
            reasonButtonId === 2
              ? { height: "22px", width: "22px" }
              : { height: "22px", width: "22px", color: "#3BA5B9" }
          }
        />
      ),
      value: "Injury",
    },
    {
      id: 3,
      title: "Fainting",
      icon: (
        <PiSpiralBold
          style={
            reasonButtonId === 3
              ? { height: "22px", width: "22px" }
              : { height: "22px", width: "22px", color: "#3BA5B9" }
          }
        />
      ),
      value: "Fainting",
    },
  ];

  const statusButton = [
    {
      id: 1,
      title: "Sent to Isolation Room",
      value: "Sent to Isolation Room",
    },
    {
      id: 2,
      title: "Sent to Hospital",
      value: "Sent to Hospital",
    },
    {
      id: 3,
      title: "Sent Home",
      value: "Sent Home",
    },
    {
      id: 4,
      title: "Back to Class",
      value: "Back to Class",
    },
  ];

  const informeButtons = [
    {
      id: 1,
      title: "Yes",
      value: "Yes",
    },
    { id: 2, title: "No", value: "No" },
  ];

  const informStatus = [
    { id: 1, title: "No Answer" },
    { id: 2, title: "Busy" },
    { id: 3, title: "Not Reached" },
    { id: 4, title: "Callback Requested" },
    { id: 5, title: "Wrong Number" },
  ];

  const handleVisitButtonClick = (value) => {
    setVisitButtonId(value);
  };

  const handleReasonButtonClick = (value) => {
    setReasonButtonId(value);
  };

  const handleStatusButtonClick = (value) => {
    setStatusButtonId(value);
  };

  const handleInformButtonClick = (value) => {
    setInformButtonId(value);
  };

  const handleDataInput = (key, value) => {
    setAddStudentData((prev) => ({
      ...prev,
      name: updateId && updateId.student_name,
      class: updateId && updateId.class,
      batch: updateId && updateId.batch,
      image: updateId && updateId.profile_pic,
      date: updateId && updateId.visit_date,
      [key]: value,
    }));
    if (key === "informed_parent" && value === "Yes") {
      setAddStudentData((prev) => ({
        ...prev,
        contact_status: "",
      }));
    }
  };

  const handleUpdateStudent = async () => {
    try {
      Commonservices.loaderON(dispatch);
      if (
        addStudentData.informed_parent === "No" &&
        addStudentData.contact_status === ""
      ) {
        toast.error("Status is Complusory");
      } else {
        let req = {
          id: updateId.id,
          visit_type: addStudentData.visit_type,
          reason: addStudentData.reason,
          situation: addStudentData.situation,
          visit_status: addStudentData.visit_status,
          informed_parent: addStudentData.informed_parent,
          contact_status: addStudentData.contact_status,
          remarks: addStudentData.remarks,
          user: userData.user_id,
          user_id: userData.name,
          user_token: "",
        };

        const apiResult = await mysqlservice.post("update_visits", req);
        if (apiResult.status === 200) {
          toast.success(apiResult.message);
        } else {
          toast.error(apiResult.message);
        }

        onClose();
        setUpdateId("");
        handleStudentData();
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleCancel = () => {
    onClose();
    setUpdateId("");
  };

  const handleStatusModalOpen = (value) => {
    if (
      value == "Sent Home" ||
      value == "Sent to Hospital" ||
      value == "Back to Class"
    ) {
      setStatusModalOpen(true);
    } else {
      handleDataInput("visit_status", value);
    }
  };

  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const handleStatusSelect = () => {
    handleDataInput("visit_status", statusButtonId);
    handleStatusModalClose();
  };

  return (
    <Drawer
      open={open}
      onClose={handleCancel}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Update Student
            </Typography>
            <Typography fontSize="12px">Enter updates about student</Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handleCancel}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <Avatar
                src={
                  updateId && updateId.profile_image
                    ? updateId.profile_image
                    : ""
                }
                sx={{
                  height: "34px",
                  width: "34px",
                }}
              />
              <Box>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  {updateId && updateId.student_name
                    ? updateId.student_name
                    : ""}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Grade {updateId && updateId.class ? updateId.class : ""} -{" "}
                  {"  "}
                  {updateId && updateId.batch ? updateId.batch : ""}
                </Typography>
                {/* <Typography sx={{ fontSize: "12px" }}>
                  {updateId.admission_no}
                </Typography> */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              textAlign={{ xs: "left", sm: "right" }}
              mt={{ xs: 2, sm: 0 }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Parents Details
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      color: "#8D949C",
                    }}
                  >
                    Name &nbsp;-
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip
                    title={
                      updateId && updateId.parent_name
                        ? updateId.parent_name
                        : ""
                    }
                    placement="top"
                  >
                    <Typography
                      sx={{ fontSize: "12px", textTransform: "capitalize" }}
                    >
                      &nbsp;{}
                      {updateId && updateId.parent_name
                        ? updateId.parent_name.length > 13
                          ? `${updateId.parent_name.substring(0, 13)}...`
                          : updateId.parent_name
                        : "--"}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      color: "#8D949C",
                    }}
                  >
                    Mobile &nbsp;-
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ fontSize: "12px", textTransform: "capitalize" }}
                  >
                    &nbsp;
                    {updateId && updateId.parent_mobile
                      ? updateId.parent_mobile
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 1 }} />
          <Box mt={2}>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Visits
              </Typography>
              <Box sx={{ display: "flex", mt: 1 }}>
                {visitButtons.map((val, index) => {
                  return (
                    <Button
                      key={index}
                      variant="contained"
                      fullWidth
                      sx={{
                        ...(addStudentData &&
                        addStudentData.visit_type === val.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : addStudentData &&
                            !addStudentData.visit_type &&
                            visitButtonId === val.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : {
                              borderRadius: 0,
                              backgroundColor: "#fff",
                              color: "#000",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#000",
                                backgroundColor: "#fff",
                              },
                            }),
                        ...(index === 0
                          ? {
                              borderTopLeftRadius: "8px",
                              borderBottomLeftRadius: "8px",
                            }
                          : {}),
                        ...(index === visitButtons.length - 1
                          ? {
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        handleVisitButtonClick(val.value);
                        handleDataInput("visit_type", val.value);
                      }}
                    >
                      {val.title}
                    </Button>
                  );
                })}
              </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />

            <Box mt={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Reason
              </Typography>
              <Box sx={{ display: "flex", mt: 1 }}>
                {reasonButton.map((data, index) => {
                  return (
                    <Button
                      key={index}
                      variant="contained"
                      fullWidth
                      sx={{
                        ...(addStudentData &&
                        addStudentData.reason === data.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : addStudentData &&
                            !addStudentData.reason &&
                            reasonButtonId === data.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : {
                              borderRadius: 0,
                              backgroundColor: "#fff",
                              color: "#000",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#000",
                                backgroundColor: "#fff",
                              },
                            }),
                        ...(index === 0
                          ? {
                              borderTopLeftRadius: "8px",
                              borderBottomLeftRadius: "8px",
                            }
                          : {}),
                        ...(index === reasonButton.length - 1
                          ? {
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        handleReasonButtonClick(data.value);
                        handleDataInput("reason", data.value);
                      }}
                    >
                      {data.title} {data.icon}
                    </Button>
                  );
                })}
              </Box>
              {addStudentData && addStudentData.reason === "Disease" ? (
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={addStudentData.situation}
                      onChange={(e) => {
                        handleDataInput("situation", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Infectious"
                        control={<Radio />}
                        label="Infectious"
                      />
                      <FormControlLabel
                        value="Non-Infectious"
                        control={<Radio />}
                        label="Non Infectious"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : addStudentData &&
                !addStudentData.reason &&
                reasonButtonId === "Disease" ? (
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={addStudentData.situation}
                      onChange={(e) => {
                        handleDataInput("situation", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Infectious"
                        control={<Radio />}
                        label="Infectious"
                      />
                      <FormControlLabel
                        value="Non-Infectious"
                        control={<Radio />}
                        label="Non Infectious"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : addStudentData && addStudentData.reason === "Injury" ? (
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={addStudentData.situation}
                      onChange={(e) => {
                        handleDataInput("situation", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Blunt"
                        control={<Radio />}
                        label="Blunt"
                      />
                      <FormControlLabel
                        value="Open"
                        control={<Radio />}
                        label="Open"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : addStudentData &&
                !addStudentData.reason &&
                reasonButtonId === "Injury" ? (
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={addStudentData.situation}
                      onChange={(e) => {
                        handleDataInput("situation", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Blunt"
                        control={<Radio />}
                        label="Blunt"
                      />
                      <FormControlLabel
                        value="Open"
                        control={<Radio />}
                        label="Open"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : (
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={addStudentData.situation}
                      onChange={(e) => {
                        handleDataInput("situation", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Fainting"
                        control={<Radio />}
                        label="Fainting"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
            </Box>
            <Divider sx={{ mt: 1 }} />

            <Box mt={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Status
              </Typography>
              <Box sx={{ display: "flex", mt: 1 }}>
                {statusButton.map((data, index) => {
                  return (
                    <Button
                      key={index}
                      variant="contained"
                      fullWidth
                      sx={{
                        ...(addStudentData &&
                        addStudentData.visit_status === data.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : addStudentData &&
                            !addStudentData.visit_status &&
                            statusButtonId === data.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : {
                              borderRadius: 0,
                              backgroundColor: "#fff",
                              color: "#000",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              // whiteSpace: "nowrap",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#000",
                                backgroundColor: "#fff",
                              },
                            }),
                        ...(index === 0
                          ? {
                              borderTopLeftRadius: "8px",
                              borderBottomLeftRadius: "8px",
                            }
                          : {}),
                        ...(index === statusButton.length - 1
                          ? {
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        handleStatusButtonClick(data.value);
                        handleStatusModalOpen(data.value);
                        // handleDataInput("visit_status", data.value);
                      }}
                      disabled={
                        updateId &&
                        updateId.visit_status != "Sent to Isolation Room" &&
                        updateId.visit_status != "Reached Clinic" &&
                        updateId.visit_status != "Sent to Clinic"
                      }
                    >
                      {data.title}
                    </Button>
                  );
                })}
              </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />

            <Box mt={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Informed Parents
              </Typography>
              <Box sx={{ display: "flex", mt: 1 }}>
                {informeButtons.map((data, index) => {
                  return (
                    <Button
                      key={index}
                      variant="contained"
                      fullWidth
                      sx={{
                        ...(addStudentData &&
                        addStudentData.informed_parent === data.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              "&:hover": {
                                boxShadow: 0,
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : addStudentData &&
                            !addStudentData.informed_parent &&
                            informButtonId === data.value
                          ? {
                              borderRadius: 0,
                              backgroundColor: "#3BA5B9",
                              color: "#fff",
                              textTransform: "capitalize",
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              fontSize: "14px",
                              "&:hover": {
                                color: "#fff",
                                backgroundColor: "#3BA5B9",
                                boxShadow: 0,
                              },
                            }
                          : {
                              boxShadow: 0,
                              border: "1px solid #C7C7C7",
                              borderRadius: 0,
                              backgroundColor: "#fff",
                              color: "#000",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              "&:hover": {
                                color: "#000",
                                backgroundColor: "#fff",
                                boxShadow: 0,
                              },
                            }),
                        ...(index === 0
                          ? {
                              borderTopLeftRadius: "8px",
                              borderBottomLeftRadius: "8px",
                            }
                          : {}),
                        ...(index === informeButtons.length - 1
                          ? {
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        handleInformButtonClick(data.value);
                        handleDataInput("informed_parent", data.value);
                      }}
                    >
                      {data.title}
                    </Button>
                  );
                })}
              </Box>
              {addStudentData && addStudentData.informed_parent === "No" ? (
                <Box sx={{ mt: 2 }}>
                  <FormControl size="small">
                    <Box sx={{ display: "flex", gap: 0.5 }}>
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        Status
                      </Typography>
                      <Typography sx={{ color: "red" }}> *</Typography>
                    </Box>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={addStudentData.contact_status}
                      onChange={(e) => {
                        handleDataInput("contact_status", e.target.value);
                      }}
                      sx={{ width: 200, backgroundColor: "#fff" }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "") {
                          return (
                            <p style={{ color: "gray" }}>
                              Select Contact Status
                            </p>
                          );
                        }
                        return selected;
                      }}
                    >
                      {informStatus.map((val, index) => {
                        return (
                          <MenuItem key={index} value={val.title}>
                            {val.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              ) : informButtonId === "No" ? (
                <Box sx={{ mt: 2 }}>
                  <FormControl size="small">
                    <Box sx={{ display: "flex", gap: 0.5 }}>
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        Contact Status
                      </Typography>
                      <Typography sx={{ color: "red" }}> *</Typography>
                    </Box>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={addStudentData.contact_status}
                      onChange={(e) => {
                        handleDataInput("contact_status", e.target.value);
                      }}
                      displayEmpty
                      sx={{
                        width: 200,
                        backgroundColor: "#fff",
                        ".MuiSelect-icon": {
                          color: "gray",
                        },
                        ".MuiSelect-select": {
                          color:
                            addStudentData.contact_status === ""
                              ? "#adb5bd"
                              : "#000",
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected === "") {
                          return (
                            <p style={{ color: "gray" }}>
                              Select Contact Status
                            </p>
                          );
                        }
                        return selected;
                      }}
                    >
                      {informStatus.map((val, index) => (
                        <MenuItem key={index} value={val.title}>
                          {val.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
            </Box>

            <Box mt={2} mb={1}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Remarks
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 1,
                  width: "100%",
                  height: "72px",
                  backgroundColor: "#fff",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#DFDFDF",
                      height: "79px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#DFDFDF",
                      height: "79px",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#DFDFDF",
                      height: "79px",
                    },
                    "& input::placeholder": {
                      color: "#878A99",
                    },
                    "& input": {
                      color: "black",
                    },
                  },
                }}
                value={addStudentData.remarks}
                onChange={(e) => {
                  let newvalue = e.target.value;
                  if (newvalue.length < 1500) {
                    handleDataInput("remarks", e.target.value);
                    setError({ remarks: "" });
                  } else {
                    setError({ remarks: "only 1500 characters allowed" });
                  }
                }}
                error={Boolean(error.remarks)}
                helperText={error.remarks}
              />
            </Box>
          </Box>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
              onClick={handleUpdateStudent}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        open={statusModalOpen}
        onClose={handleStatusModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} textAlign={"center"}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Are you sure you want to mark this status ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Once confirmed, it cannot be changed.
          </Typography>
          <Box display={"flex"} gap={1} justifyContent={"end"} mt={2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "13px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
              onClick={handleStatusSelect}
            >
              Proceed
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "13px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
              onClick={handleStatusModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Drawer>
  );
};

export default UpdateDrawer;
