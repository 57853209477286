import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Form from "./Form";
import Brand from "../../Asset/brand.png";
import Aside from "./Aside";
import AppLogo from "../../Asset/applogo.png";

const Index = () => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={7}
        sx={
          theme.palette.mode === "dark"
            ? { backgroundColor: "#000" }
            : { backgroundColor: "#fff" }
        }
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "center",
            transform:"translate(0px,10px)"
          }}
        >
          <img
            src={AppLogo}
            alt=""
            style={{
              height: "30px",
              width: "30px",
            }}
          />
          <Typography sx={{ fontSize: "30px", fontWeight: 600 }}>
            BM CLINIC
          </Typography>
        </Box>
        <Form />
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: { xs: "none", md: "flex" },
          backgroundColor: "#338FA0",
          borderRadius: "24px",
        }}
      >
        <Aside />
      </Grid>
    </Grid>
  );
};

export default Index;
