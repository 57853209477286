import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";

const SimpleDropdown = ({
  title,
  label,
  value,
  onChange,
  placeholder,
  style,
  isrequired,
  menuitem = [],
  titleStyle,
}) => {
  return (
    <FormControl size="small">
      <Box sx={{ display: "flex", gap: 0.5 }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 600, ...titleStyle }}>
          {title}
        </Typography>
        {isrequired ? <Typography sx={{ color: "red" }}> *</Typography> : ""}
      </Box>
      <InputLabel
        id="demo-simple-select-label"
        sx={{
          color: "gray", // Label color
          "&.Mui-focused": {
            color: "#000", // Label color when focused
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
        sx={{
          ...style,
        }}
      >
        {menuitem && menuitem.length ? (
          menuitem.map((data, index) => {
            return (
              <MenuItem value={data.value} key={index}>
                {data.name}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem></MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SimpleDropdown;
