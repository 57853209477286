import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Textfield from "../../../Components/Textfield";
import Datefeild from "../../../Components/Datefeild";
import dayjs from "dayjs";

const VaccineDetailpage = ({ studentData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const personalDetaillist = [
    { id: 1, title: "Student Full Name", value: "student_full_name" },
    { id: 2, title: "Gender", value: "gender" },
    { id: 3, title: "Emirates ID", value: "emirates_id" },
    { id: 4, title: "DOB", value: "dob" },
    { id: 6, title: "School Name", value: "school_name" },
    { id: 5, title: "Grade", value: "grade" },
    { id: 7, title: "Year", value: "year" },
  ];

  const disAgreelist = [
    {
      id: 1,
      title:
        "Vaccination dose marked above has been administered previously (please provide the proof).",
      value: "administered_previously",
    },
    {
      id: 2,
      title:
        "Medical condition that prevents from being vaccinated currently (please provide a medical exemption report from the treating physician)",
      value: "condition_for_disagree",
    },
    { id: 3, title: "Other reason (please specify)", value: "other_reason" },
  ];

  const parentDetail = [
    { id: 1, title: "Parent / Legal Guardian Name", value: "parent_name" },
    { id: 2, title: "Relationship", value: "relationship" },
    // {
    //   id: 3,
    //   title: "Parent / Legal Guardian Signature",
    //   value: "parent_signature",
    // },
  ];

  const onChange = (date, dateString) => {
    let newDate = dayjs(date);
    studentData.setFieldValue("declare_Date", newDate);
  };

  return (
    <Box>
      <Box mt={4}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Important Notes
        </Typography>
        <Box mt={2} display={"flex"} flexDirection={"column"} gap={0.5}>
          <Typography
            sx={{
              fontSize: { md: "12px", lg: "13px", xl: "14px" },
              fontWeight: 500,
            }}
          >
            <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
            Please submit the original vaccine card.
          </Typography>

          <Typography
            sx={{
              fontSize: { md: "12px", lg: "13px", xl: "14px" },
              fontWeight: 500,
            }}
          >
            <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
            Please submit a copy of any medical report related to the student's
            health, which might affect giving your child the vaccine.
          </Typography>

          <Typography
            sx={{
              fontSize: { md: "12px", lg: "13px", xl: "14px" },
              fontWeight: 500,
            }}
          >
            <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
            Please inform the school doctor/ nurse if the student received any
            vaccination outside the school (during the academic year)
          </Typography>

          <Typography
            sx={{
              fontSize: { md: "12px", lg: "13px", xl: "14px" },
              fontWeight: 500,
            }}
          >
            <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
            Please inform the school doctor/nurse of any changes in the students
            health condition during the school year and attach the medical
            report, if any.
          </Typography>
        </Box>
      </Box>

      <Box mt={2}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Section A: This section should be filled by school Doctor / Nurse
        </Typography>
        <Grid container spacing={1} mt={1}>
          {personalDetaillist.map((val, ind) => (
            <Grid item xs={12} md={3.5} lg={2.4} key={ind}>
              <Textfield
                label={val.title}
                isRequired={true}
                value={studentData.values[val.value]}
                onChange={(e) => {
                  studentData.setFieldValue(val.value, e.target.value);
                }}
                placeholder={`Enter ${val.title}`}
                helperText={studentData.errors[val.value]}
                error={studentData.errors[val.value]}
                isDisable={true}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%", mt: 2 }}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Due Vaccines
        </Typography>
        <Divider sx={{ ml: 2, flexGrow: 1 }} />
      </Box>
      <Box mt={2}>
        <Typography>
          According to the National Immunization Program, the student is due for
          the below marked vaccines as recommended by Dubai Health Authority.
        </Typography>
        <Grid container mt={1}>
          <Grid item xs={12} md={3.5} lg={2.4}>
            <Textfield
              label={"Date"}
              defaultValue={studentData.values.vaccination_date}
              isDisable={true}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", width: "100%", mt: 2 }}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Vaccines
        </Typography>
        <Divider sx={{ ml: 2, flexGrow: 1 }} />
      </Box>

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Textfield
              label={"Vaccine"}
              defaultValue={studentData.values.vaccination_type}
              isDisable={true}
            />
            <Textfield
              label={"Dose No:"}
              defaultValue={studentData.values.vaccination_dose}
              isDisable={true}
              style={{ width: "40px" }}
            />
            <FormControlLabel
              control={<Checkbox size="small" defaultChecked />}
              label="Due"
              sx={{ transform: "translate(0px,10px)" }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Section B:- This part to be filled & signed by parents or guardians
        </Typography>

        <Grid container mt={0.5}>
          <Grid item xs={12} sm={6} md={4}>
            <Box display={"flex"} alignItems={"start"}>
              <Checkbox
                size="small"
                checked={studentData.values.agree_consent}
                onChange={(value) => {
                  studentData.setFieldValue("agree_consent", true);
                  studentData.setFieldValue("administered_previously", false);
                  studentData.setFieldValue("condition_for_disagree", false);
                  studentData.setFieldValue("other_reason", false);
                  studentData.setFieldValue("update_immunisation", false);
                  studentData.setFieldValue("other_reason_comment", "");
                }}
              />
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  transform: "translate(0px,10px)",
                }}
              >
                I agree to vaccinate with the vaccines marked above
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box display={"flex"} alignItems={"start"}>
              <Checkbox
                size="small"
                checked={!studentData.values.agree_consent}
                onChange={(value) => {
                  studentData.setFieldValue("agree_consent", false);
                }}
              />
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  transform: "translate(0px,10px)",
                }}
              >
                I disagree to administer the vaccines marked above:
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          If disagree for vaccination, please state the reason:
        </Typography>
        {disAgreelist.map((val, index) => (
          <Box display={"flex"} alignItems={"start"} key={index}>
            <Checkbox
              size="small"
              checked={studentData.values[val.value]}
              onChange={(value) => {
                studentData.setFieldValue(val.value, value.target.checked);
              }}
            />
            <Typography
              sx={{
                fontSize: { md: "12px", lg: "13px", xl: "14px" },
                transform: "translate(0px,10px)",
              }}
            >
              {val.title}
            </Typography>
          </Box>
        ))}
        <Box width={{ xs: "100%", md: "328px" }} ml={{ xs: 0, md: 1 }}>
          <Textfield
            value={studentData.values.other_reason_comment}
            onChange={(e) => {
              studentData.setFieldValue("other_reason_comment", e.target.value);
            }}
            placeholder={"Enter Reason"}
          />
        </Box>
        <Box display={"flex"} alignItems={"start"}>
          <Checkbox
            size="small"
            checked={studentData.values.update_immunisation}
            onChange={(value) => {
              studentData.setFieldValue(
                "update_immunisation",
                value.target.checked
              );
            }}
          />
          <Typography
            sx={{
              fontSize: { md: "12px", lg: "13px", xl: "14px" },
              transform: "translate(0px,10px)",
            }}
          >
            The school medical staff has been provided with an updated
            immunization record for my son/daughter
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={1} mt={2}>
        {parentDetail.map((val, ind) => (
          <Grid item xs={12} md={3.5} lg={2.4} key={ind}>
            <Textfield
              label={val.title}
              value={studentData.values[val.value]}
              onChange={(e) => {
                studentData.setFieldValue(val.value, e.target.value);
              }}
              placeholder={`Enter ${val.title}`}
            />
          </Grid>
        ))}
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Datefeild
            label={"Date"}
            value={studentData.values.declare_Date}
            onChange={onChange}
            placeholder={"Select Date"}
            helperText={studentData.errors.declare_Date}
            // error={studentData.errors.declare_Date}
            style={
              studentData.errors.declare_Date
                ? { border: "1px solid red" }
                : { border: "1px solid #ced4da" }
            }
            isrequired={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VaccineDetailpage;
