import React from "react";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import IconLogo from "../../Components/IconLogo";
import Update from "../../Asset/Update.png";
import Trash from "../../Asset/Trash.png";
import moment from "moment";
import { useParams } from "react-router-dom";

const TableView = ({
  studentData,
  allChecked,
  handleAllChecked,
  checked,
  handleCheckBoxselect,
  pages,
  pageCount,
  handlePageChange,
  rowsPerPage,
  handleUpdatelist,
  privilege,
}) => {
  const url_params = useParams();
  const theme = useTheme();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 0px 2px black",
          display: "flex",
          flexDirection: "column",
        }}
        className="custom-scrollbar"
      >
        <Table>
          <TableHead>
            <TableRow
              sx={
                theme.palette.mode == "dark"
                  ? { backgroundColor: "#212529" }
                  : { backgroundColor: "#F3F6F9" }
              }
            >
              {/* <TableCell align="left">
                <Checkbox
                  size="small"
                  checked={allChecked}
                  onChange={(e) => {
                    handleAllChecked(e.target.checked);
                  }}
                />
              </TableCell> */}
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600 }}
              >
                SL
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600 }}
              >
                Name
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Class & Batch
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600 }}
              >
                Date
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Visits
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Reason
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Situation
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Visit Status
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Informed Parents
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Contact Status
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
              >
                Remarks
              </TableCell>
              {privilege.permission == "Edit" ? (
                <TableCell
                  align="right"
                  sx={
                    theme.palette.mode == "dark"
                      ? {
                          fontSize: "13px",
                          fontWeight: 600,
                          position: { xs: "relative", md: "sticky" },
                          right: 0,
                          backgroundColor: "#343a40",
                        }
                      : {
                          fontSize: "13px",
                          fontWeight: 600,
                          position: { xs: "relative", md: "sticky" },
                          right: 0,
                          backgroundColor: "#F3F6F9",
                        }
                  }
                  // colSpan={2}
                >
                  Action
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {studentData && studentData.length ? (
              studentData.map((val, index) => {
                let sl_no = index + 1 + (pages - 1) * rowsPerPage;
                return (
                  <TableRow key={index}>
                    {/* <TableCell align="left">
                      <Checkbox
                        size="small"
                        id={val.id.toString()}
                        name={val.id.toString()}
                        value={val.id}
                        checked={checked.includes(val.id) ? true : false}
                        onChange={(e) => {
                          handleCheckBoxselect(val.id, e.target.checked);
                        }}
                      />
                    </TableCell> */}
                    <TableCell align="left" sx={{ color: "#915ECC" }}>
                      {sl_no < 10 ? 0 : ""}
                      {sl_no}
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Avatar
                          sx={{ height: "32px", width: "32px" }}
                          src={val && val.profile_pic ? val.profile_pic : ""}
                        />
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.student_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Grade {val.class ? val.class : ""}-
                      {val.batch ? val.batch : ""}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {moment(val.createdAt).format("MMMM D, YYYY h:mm a")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {val.visit_type === "Direct_visit"
                        ? "Direct visit"
                        : val.visit_type === "By Teacher"
                        ? "By Teacher"
                        : ""}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {val.reason ? val.reason : "--"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {val.situation ? (
                        <Chip
                          sx={
                            val.situation === "Non-Infectious" ||
                            val.situation === "Blunt"
                              ? {
                                  borderRadius: "5px",
                                  backgroundColor: "#DEEFFF",
                                  color: "#0071DA",
                                }
                              : {
                                  borderRadius: "5px",
                                  backgroundColor: "#FCD6D2",
                                  color: "#E03B26",
                                }
                          }
                          size="small"
                          label={val.situation}
                        />
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {val.visit_status ? (
                        <Chip
                          sx={{ borderRadius: "5px" }}
                          size="small"
                          label={val.visit_status}
                        />
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {val.informed_parent ? val.informed_parent : "--"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {val.contact_status ? val.contact_status : "--"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip title={val.remarks}>
                        <Typography fontSize={"13px"}>
                          {val.remarks
                            ? val.remarks.length > 15
                              ? `${val.remarks.substring(0, 15)}...`
                              : val.remarks
                            : "--"}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    {privilege.permission == "Edit" ? (
                      <TableCell
                        align="right"
                        sx={
                          theme.palette.mode == "dark"
                            ? {
                                position: { xs: "relative", md: "sticky" },
                                right: 0,
                                backgroundColor: "#343a40",
                              }
                            : {
                                position: { xs: "relative", md: "sticky" },
                                right: 0,
                                backgroundColor: "#F3F6F9",
                              }
                        }
                      >
                        <Tooltip title="Update">
                          <IconButton
                            sx={{
                              backgroundColor: "#D0F2F9",
                              borderRadius: "5px",
                              height: "30px",
                              width: "30px",
                              "&:hover": {
                                backgroundColor: "#D0F2F9",
                              },
                            }}
                            onClick={() => {
                              handleUpdatelist(val);
                            }}
                          >
                            <IconLogo
                              image={Update}
                              size="22"
                              radius="0"
                              right="12px"
                              top="-4px"
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {/* <TableCell align="left" sx={{ pl: 0 }}>
                    <Tooltip title="Delete">
                      <IconButton
                        sx={{
                          backgroundColor: "#EE6352",
                          borderRadius: "5px",
                          height: "30px",
                          width: "30px",
                          ml: 0.5,
                          "&:hover": {
                            backgroundColor: "#EE6352",
                          },
                        }}
                      >
                        <IconLogo
                          image={Trash}
                          size="22"
                          radius="0"
                          right="12px"
                          top="-4px"
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={13}>
                  No Data to Show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>{" "}
      {studentData && studentData.length > 0 ? (
        <Pagination
          sx={{
            m: 1,
            display: "flex",
            justifyContent: "end",
            "& .Mui-selected": {
              color: "#fff",
              backgroundColor: "#3BA5B9",
            },
            "& .MuiPaginationItem-page": {
              color: "#3BA5B9",
              "&.Mui-selected": {
                backgroundColor: "#3BA5B9",
                color: "#fff",
              },
            },
          }}
          count={pageCount}
          page={pages}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default TableView;
