import { Box, Button, Checkbox, Typography } from "@mui/material";
import React, { useState } from "react";
import Textfield from "../../Components/Textfield";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "../../state/index";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";
import Commonservices from "../../service/Commonservices";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import APPCONSTANTS from "../../config/constant";
import Googlelogin from "./Googlelogin";

const Form = () => {
  const G_CLIENT_ID = APPCONSTANTS.G_CLIENT_ID;

  useGoogleOneTapLogin({
    onSuccess: (res) => {
      let email = res.email;
      handleOnetapLogin(email);
    },
    onError: (err) => {
      console.log(err);
    },
    googleAccountConfigs: {
      client_id: G_CLIENT_ID,
    },
  });

  const [isPassword, setIsPassword] = useState(false);
  const [loginInput, setLoginInput] = useState({
    username: localStorage.email || "",
    password: localStorage.password || "",
  });
  const [isChecked, setIsChecked] = useState(localStorage.isChecked || false);
  const [forgetPass, setForgetPass] = useState(1);
  const [resetEmail, setResetEmail] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRegex = /\S+@\S+\.\S+/;

  const handleInput = (feild, e) => {
    let value = e.target.value;
    let result = value.trim();

    setLoginInput((prev) => ({
      ...prev,
      [feild]: result,
    }));
  };

  const handleShowPass = () => {
    setIsPassword(!isPassword);
  };

  const handleSubmit = async (e) => {
    Commonservices.loaderON(dispatch);
    try {
      e.preventDefault();
      let req = {
        email: loginInput.username,
        password: loginInput.password,
      };
      const apiResult = await mysqlservice.post("login", req);
      if (apiResult && apiResult.status === 200) {
        toast.success("Logged in Successfully");
        dispatch(
          setLogin({
            user: apiResult.data,
            token: apiResult.data.email,
          })
        );
        if (isChecked) {
          localStorage.setItem("isChecked", isChecked);
          localStorage.setItem("email", loginInput.username);
          localStorage.setItem("password", loginInput.password);
        } else {
          localStorage.removeItem("isChecked");
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }
        navigate("/home");
      } else {
        toast.error(apiResult.message);
      }

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleSendlink = async (e) => {
    Commonservices.loaderON(dispatch);
    e.preventDefault();
    if (resetEmail === "") {
      toast.error("Email Required to Continue");
    } else if (emailRegex.test(resetEmail)) {
      try {
        let req = {
          email: resetEmail,
        };
        const apiResult = await mysqlservice.post("forgetpassword", req);
        // setResetEmail("");
        if (apiResult.status === 200) {
          toast.success(apiResult.message);
        } else {
          toast.error(apiResult.message);
        }
        setLoginInput({
          email: "",
          password: "",
        });
        Commonservices.loaderOFF(dispatch);
      } catch (error) {
        console.log(error);
        Commonservices.loaderOFF(dispatch);
      }
    } else {
      toast.error("Enter a Valid Email");
    }
  };

  const handleOnetapLogin = async (val) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {};
      req.email = val;
      let apiResult = await mysqlservice.post("onetap_login", req);
      if (apiResult && apiResult.status === 200) {
        toast.success("Logged in Successfully");
        dispatch(
          setLogin({
            user: apiResult.data,
            token: apiResult.data.email,
          })
        );
        navigate("/home");
      } else {
        toast.error(apiResult.message);
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        p: { xs: 2, md: 0 },
      }}
    >
      {forgetPass === 1 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
         
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: "36px", fontWeight: 600 }}>
              Sign in
            </Typography>
            <Typography fontSize={"16px"}>
              Welcome back! Please enter your details.
            </Typography>
          </Box>
          <Box mt={3}>
            <Textfield
              label={"Username"}
              value={loginInput.username}
              placeholder={"Enter Username"}
              style={{
                width: { xs: "100%", md: "360px" },
                fontSize: "14px",
                fontWeight: 500,
                // color: "#000",
                input: {
                  color: "#344054",
                },
              }}
              onChange={(e) => {
                handleInput("username", e);
              }}
            />
            <br />
            <Textfield
              label={"Password"}
              value={loginInput.password}
              placeholder={"Enter Password"}
              style={{
                width: { xs: "100%", md: "360px" },
                fontSize: "14px",
                fontWeight: 500,
                // color: "#344054",
                input: {
                  color: "#344054",
                },
              }}
              type={!isPassword ? "password" : "text"}
              endAdornment={
                <MdOutlineRemoveRedEye
                  style={{ height: "20px", width: "20px" }}
                />
              }
              onEndClick={handleShowPass}
              onChange={(e) => {
                handleInput("password", e);
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(!isChecked);
                  }}
                />
                <Typography sx={{ fontSize: "14px" }}>Remember me</Typography>
              </Box>
              <Button
                sx={{
                  fontSize: "14px",
                  color: "#3BA5B9",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setForgetPass(2);
                }}
              >
                Forgot password
              </Button>
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                width: "100%",
                mt: 2,
                color: "#fff",
                fontSize: "16px",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Sign in
            </Button>
            {/* <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#fff",
                width: "100%",
                mt: 2,
                color: "#000",
                fontSize: "16px",
                fontWeight: 600,
                textTransform: "capitalize",
                display: "flex",
                gap: 2,
                boxShadow: 0,
                border: "1px solid #D0D5DD",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <FcGoogle /> Sign in with Google
            </Button> */}
            <Box
              mt={2}
              display={"flex"}
              justifyContent={"center"}
              width={"100%"}
            >
              <Googlelogin />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{ fontSize: { xs: "26px", md: "30px" }, fontWeight: 600 }}
            >
              Forgot Password
            </Typography>
            <Typography fontSize={{ xs: "13px", md: "14px" }}>
              We’ll email you instructions to reset your password.
            </Typography>
          </Box>
          <Box mt={3}>
            <Textfield
              label={"Email"}
              value={resetEmail}
              placeholder={"Enter Email"}
              style={{
                width: { xs: "100%", md: "360px" },
                fontSize: "14px",
                fontWeight: 500,
                // color: "#344054",
                input: {
                  color: "#344054",
                },
              }}
              onChange={(e) => {
                setResetEmail(e.target.value);
              }}
            />

            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                width: "100%",
                mt: 2,
                color: "#fff",
                fontSize: "16px",
                fontWeight: 600,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3BA5B9",
                },
              }}
              onClick={(e) => {
                handleSendlink(e);
              }}
            >
              Send
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#fff",
                width: "100%",
                mt: 2,
                color: "#3BA5B9",
                fontSize: "14px",
                fontWeight: 600,
                textTransform: "capitalize",
                display: "flex",
                gap: 2,
                boxShadow: 0,
                border: "1px solid #D0D5DD",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              onClick={() => setForgetPass(1)}
            >
              Back to login
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Form;
