const X_AUTH_TOKEN = "tq355lY3MJyd8Uj2ySzm";
const G_CLIENT_ID = "1067759813651-vp89rep2hftdln6qn8kk0vbjj2jg91es.apps.googleusercontent.com";
const FILE_SERVER_URL_SQA = "https://sqa.docme.online/bm-school/Docme-UI/";
const FILE_SERVER_URL = "https://teamsqa4000.educore.guru";

const DUMMY_USER_ICON = "https://teamsqa4000.educore.guru/uploads/ic_person_black.svg";
const SITE_URL = "https://teamsqa.educore.guru/";
const LIVEFS = "https://nims4000.educore.guru";

const SQA_URL = "https://sqa.docme.online/";
const SQA_URL_LIVE = "https://bmark.nimsuae.com/";

// const NODE_SQL_SERVER = "http://localhost:8081/v1/"; //localHost
const NODE_SQL_SERVER = "https://apiqa-bmclinic.docme.online/v1"; //test

const classInRoman = [
  { number: "KG1", roman: "KG1" },
  { number: "KG2", roman: "KG2" },
  { number: 1, roman: "I" },
  { number: 2, roman: "II" },
  { number: 3, roman: "III" },
  { number: 4, roman: "IV" },
  { number: 5, roman: "V" },
  { number: 6, roman: "VI" },
  { number: 7, roman: "VII" },
  { number: 8, roman: "VIII" },
  { number: 9, roman: "IX" },
  { number: 10, roman: "X" },
  { number: 11, roman: "XICM" },
  { number: 11, roman: "XISC" },
  { number: 12, roman: "XIICM" },
  { number: 12, roman: "XIISC" },
];

const APPCONSTANTS = {
  X_AUTH_TOKEN: X_AUTH_TOKEN,
  G_CLIENT_ID: G_CLIENT_ID,
  FILE_SERVER_URL: FILE_SERVER_URL,
  FILE_SERVER_URL_SQA: FILE_SERVER_URL_SQA,
  DUMMY_USER_ICON: DUMMY_USER_ICON,
  SITE_URL: SITE_URL,
  SQA_URL_LIVE: SQA_URL_LIVE,
  LIVEFS: LIVEFS,
  NODE_SQL_SERVER: NODE_SQL_SERVER,
  SQA_URL: SQA_URL,
  classInRoman: classInRoman,
};
export default APPCONSTANTS;
