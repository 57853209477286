import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../App.css";
import Breadcrums from "../../Components/Breadcrums";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@emotion/styled";
import Dropdown from "../../Components/Dropdown";
import Cardcomp from "../../Components/Cardcomp";
import { ImUserPlus } from "react-icons/im";
import AcademicCap from "../../Asset/AcademicCap.png";
import Clinicimg from "../../Asset/hugeiconsclinic.png";
import Stethoscope from "../../Asset/Stethoscope.png";
import Syringe from "../../Asset/Syringe.png";
import Plain from "../../Asset/Plain.png";
import Calendarcomp from "./Calendarcomp";
import Chartcomp from "./Chartcomp";
import TableComp from "./TableComp";
import AddIcon from "@mui/icons-material/Add";
import Skeltoncomp from "../../Components/Skeltoncomp";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import DrawerComp from "../ClinicalVisit/DrawerComp";
import TrackingTable from "./TrackingTable";

const Index = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectRange, setSelectRange] = useState("");
  const [selectmonth, setselectMonth] = useState("");
  const [visitData, setVisitData] = useState({});
  const [open, setOpen] = useState(false);
  const [isupdate, setIsupadte] = useState(false);
  const [greeting, setGreeting] = useState("");
  const [trackData, setTrackData] = useState([]);
  const [markedAsRead, setMarkedAsRead] = useState({});
  const [range, setRange] = useState("");

  const dispatch = useDispatch();
  const theme = useTheme();

  const userData = useSelector((state) => state.user);

  const privilege = userData && userData.privileges ? userData.privileges : [];

  const isClinicPremission =
    privilege && privilege.find((data) => data.module == "clinical_visit");

  useEffect(() => {
    handleDashBoardData();
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, [selectRange, selectmonth, isupdate]);

  useEffect(() => {
    const timers = trackData.map((data) => {
      const visitId = `${data.admission_no}-${data.createdAt}`;

      if (data.visit_status == "Sent to Clinic" && data.status.length < 2) {
        const sentTime = new Date(data.createdAt).getTime();
        const currentTime = Date.now();

        const timeDiff = currentTime - sentTime;

        if (timeDiff < 10 * 60 * 1000) {
          const remainingTime = 10 * 60 * 1000 - timeDiff;

          const timer = setTimeout(() => {
            setMarkedAsRead((prev) => ({
              ...prev,
              [visitId]: true,
            }));
          }, remainingTime);

          return timer;
        } else {
          setMarkedAsRead((prev) => ({
            ...prev,
            [visitId]: true,
          }));
        }
      }
      // const isBackToClass =
      //   data &&
      //   data.status &&
      //   data.status.find((val) => val.visit_status === "Back to Class");

      // const isReachedClass =
      //   data &&
      //   data.status &&
      //   data.status.find((val) => val.visit_status === "Reached Class");

      // if (data.visit_status == "Back to Class") {
      //   const sentTime = new Date(isBackToClass.Added_on).getTime();
      //   const currentTime = Date.now();

      //   const timeDiff = currentTime - sentTime;

      //   if (timeDiff < 10 * 60 * 1000) {
      //     const remainingTime = 10 * 60 * 1000 - timeDiff;

      //     const timer = setTimeout(() => {
      //       setMarkedAsRead((prev) => ({
      //         ...prev,
      //         [visitId]: true,
      //       }));
      //     }, remainingTime);

      //     return timer;
      //   } else {
      //     setMarkedAsRead((prev) => ({
      //       ...prev,
      //       [visitId]: true,
      //     }));
      //   }
      // }

      // if (isReachedClass && isReachedClass.visit_status === "Reached Class") {
      //   setMarkedAsRead((prev) => ({
      //     ...prev,
      //     [visitId]: false,
      //   }));
      // }

      return null;
    });

    return () => {
      timers.forEach((time) => clearTimeout(time));
    };
  }, [trackData]);

  const handleSelectedYear = (option) => {
    setSelectedYear(option);
  };

  const handleRange = (value) => {
    setSelectRange(value);
    if (value == "") {
      setRange("All");
    } else {
      setRange(value);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setIsupadte(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const years = [
    { id: 1, name: "2024-2025" },
    { id: 2, name: "2023-2024" },
    { id: 3, name: "2022-2023" },
    { id: 4, name: "2021-2022" },
  ];

  const doctorCount =
    visitData && visitData.user_count
      ? visitData.user_count.find((data) => data.role === "30")
      : "";

  const nurseCount =
    visitData && visitData.user_count
      ? visitData.user_count.find((data) => data.role === "40")
      : "";

  const clinicalData = [
    { title: "Total Students Count", count: 7500, icon: AcademicCap },
    {
      title: "Total Clinical Visits",
      count: visitData && visitData.visit_count ? visitData.visit_count : 0,
      icon: Clinicimg,
    },
    {
      title: "Total Doctors",
      count: doctorCount ? doctorCount.count : "0",
      icon: Stethoscope,
    },
    {
      title: "Total Nurses",
      count: nurseCount ? nurseCount.count : "0",
      icon: Syringe,
    },
    {
      title: "Total Notifications Send",
      count:
        visitData && visitData.notification_count
          ? visitData.notification_count
          : 0,
      icon: Plain,
    },
  ];

  const recentVaccinHeader = [
    { id: 1, name: "NAME", value: "name", isClassBatch: true },
    { id: 2, name: "TOTAL DOSE", value: "totalDose", isCenter: true },
    { id: 3, name: "RECENT DOSE", value: "recentDose", isRight: true },
  ];

  const Tabledata = [
    {
      id: 1,
      name: "Dominic Charlton",
      class: 5,
      totalDose: 2,
      recentDose: "BCG: Bacillus, Calmette-Guerin",
    },
    {
      id: 2,
      name: "Amelie Townsend",
      class: 7,
      totalDose: 3,
      recentDose: "DPT: Diphtheria, Pertussis and Tetanus",
    },
    {
      id: 1,
      name: "Dominic Charlton",
      class: 5,
      totalDose: 2,
      recentDose: "BCG: Bacillus, Calmette-Guerin",
    },
    {
      id: 2,
      name: "Amelie Townsend",
      class: 7,
      totalDose: 3,
      recentDose: "DPT: Diphtheria, Pertussis and Tetanus",
    },
    {
      id: 1,
      name: "Dominic Charlton",
      class: 5,
      totalDose: 2,
      recentDose: "BCG: Bacillus, Calmette-Guerin",
    },
    {
      id: 2,
      name: "Amelie Townsend",
      class: 7,
      totalDose: 3,
      recentDose: "DPT: Diphtheria, Pertussis and Tetanus",
    },
    {
      id: 1,
      name: "Dominic Charlton",
      class: 5,
      totalDose: 2,
      recentDose: "BCG: Bacillus, Calmette-Guerin",
    },
    {
      id: 2,
      name: "Amelie Townsend",
      class: 7,
      totalDose: 3,
      recentDose: "DPT: Diphtheria, Pertussis and Tetanus",
    },
  ];

  const handleDashBoardData = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        graph_date: selectRange,
        pannelchange: selectmonth,
      };

      let apiResult = await mysqlservice.post("get_visit_details", req);
      setVisitData(apiResult.data);
      // await handleVisitToday();
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleVisitToday = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
      };

      let apiResult = await mysqlservice.post("track_today", req);
      setTrackData(apiResult.data);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "DASHBOARD",
            icon: "",
            link: true,
            path: "/home",
          },
        ]}
      />
      <Box p={2}>
        <Box display={"flex"} flexWrap={"wrap"} width={"100%"}>
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              {greeting}
            </Typography>
            <Typography sx={{ fontSize: "13px", color: "#878A99" }}>
              Here's what's happening with your clinic today.
            </Typography>
          </Box>
          <Box
            sx={{
              // display: "flex",
              // gap: 1,
              ml: { xs: 0, md: "auto" },
              // width: "380px",
            }}
          >
            {isClinicPremission && isClinicPremission.permission == "Edit" ? (
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  height: "48px",
                  textTransform: "uppercase",
                  display: "flex",
                }}
                onClick={handleOpen}
              >
                <ImUserPlus style={{ height: "20px", width: "20px" }} /> &nbsp;
                Add Student
              </Button>
            ) : (
              ""
            )}
            {/* <Dropdown
              label={"Academic year 2022"}
              options={years}
              value={selectedYear}
              onChange={handleSelectedYear}
              style={{ height: "48px" }}
            /> */}
          </Box>

          <Grid container mt={2} spacing={1}>
            {clinicalData
              ? clinicalData.map((val, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={2.4} key={index}>
                      <Cardcomp
                        title={val.title}
                        count={val.count}
                        icon={val.icon}
                      />
                    </Grid>
                  );
                })
              : ""}
          </Grid>
          <Grid container mt={2} spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <Card sx={{ p: 2, pt: 0, height: "622px" }}>
                <Calendarcomp
                  setselectMonth={setselectMonth}
                  visitData={visitData}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Chip
                    size="small"
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#FECF73",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                  <Typography
                    fontSize={"13px"}
                    sx={
                      theme.palette.mode === "dark"
                        ? { color: "#e9ecef " }
                        : { color: "#020617" }
                    }
                  >
                    The yellow color indicates the total number of daily visits.
                  </Typography>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={3.5}>
              <Card sx={{ p: 2, pt: 3, height: "622px" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                    CLINIC VISITS
                  </Typography>
                  <Box sx={{ display: "flex", ml: "auto", gap: 0.3 }}>
                    <Tooltip title="All">
                      <IconButton
                        sx={
                          range == "All"
                            ? {
                                backgroundColor: "#EBF2FE",
                                borderRadius: "5px",
                                height: "30px",
                                width: "30px",
                                "&:hover": {
                                  backgroundColor: "#EBF2FE",
                                },
                                border: "1px solid #878A99",
                              }
                            : {
                                backgroundColor: "#EBF2FE",
                                borderRadius: "5px",
                                height: "30px",
                                width: "30px",
                                "&:hover": {
                                  backgroundColor: "#EBF2FE",
                                },
                              }
                        }
                        onClick={() => {
                          handleRange("");
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", color: "#3577F1" }}>
                          ALL
                        </Typography>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="1 Month">
                      <IconButton
                        sx={
                          range == "1"
                            ? {
                                backgroundColor: "#ECEEF3",
                                borderRadius: "5px",
                                height: "30px",
                                width: "30px",
                                "&:hover": {
                                  backgroundColor: "#ECEEF3",
                                },
                                border: "1px solid #878A99",
                              }
                            : {
                                backgroundColor: "#ECEEF3",
                                borderRadius: "5px",
                                height: "30px",
                                width: "30px",
                                "&:hover": {
                                  backgroundColor: "#ECEEF3",
                                },
                                border: "2px solid #FFF",
                              }
                        }
                        onClick={() => {
                          handleRange("1");
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", color: "#915ECC" }}>
                          1M
                        </Typography>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="6 Month">
                      <IconButton
                        sx={
                          range == "6"
                            ? {
                                backgroundColor: "#EBF2FE",
                                borderRadius: "5px",
                                height: "30px",
                                width: "30px",
                                "&:hover": {
                                  backgroundColor: "#EBF2FE",
                                },
                                border: "1px solid #878A99",
                              }
                            : {
                                backgroundColor: "#EBF2FE",
                                borderRadius: "5px",
                                height: "30px",
                                width: "30px",
                                "&:hover": {
                                  backgroundColor: "#EBF2FE",
                                },
                              }
                        }
                        onClick={() => {
                          handleRange("6");
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", color: "#3577F1" }}>
                          6M
                        </Typography>
                      </IconButton>
                    </Tooltip>
                    {/* <IconButton
                      sx={{
                        backgroundColor: "#3BA5B9",
                        borderRadius: "5px",
                        height: "30px",
                        width: "30px",
                        "&:hover": {
                          backgroundColor: "#3BA5B9",
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton> */}
                  </Box>
                </Box>
                <Chartcomp visitData={visitData} />
              </Card>
            </Grid>

            <Grid item xs={12} lg={4.5}>
              <Card sx={{ p: 0, height: "250px" }}>
                {/* <TableComp
                  title={"RECENT VACCINATIONS GIVEN"}
                  tableHeader={recentVaccinHeader}
                  Tabledata={Tabledata}
                  showButton={true}
                  type={"vaccine"}
                /> */}
                <Skeltoncomp />
              </Card>

              <Card sx={{ p: 0, height: "360px", mt: 1.5 }}>
                {/* <TrackingTable
                  title={"STUDENT TRACKING"}
                  Tabledata={trackData}
                  path={"/track"}
                  markedAsRead={markedAsRead}
                /> */}
                <Skeltoncomp />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DrawerComp open={open} onClose={handleClose} setUpdateId={setIsupadte} />
    </>
  );
};

export default Index;
